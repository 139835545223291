import checkDisplay from '../check-display'
import includeInterstitialToHtml from '../include-interstitial-to-html'
import pushEventToDataLayer from '../../../../modules/push-event-to-data-layer'
import consoleLogger from '../../../../modules/console-logger'
import { checkFrequencyCap, writeToSessionStorage } from '../check-frequency-cap'

const interstitial = ({ googletag, config, viewportsConfig, ads }) => {
  const [consoleLog] = consoleLogger('AdsLog')

  const show = () => {
    let showInterstitial = true
    if (config.showInViewports) {
      let enabledSizes = config.showInViewports
      let currentSize = checkDisplay(viewportsConfig)
      showInterstitial = enabledSizes.includes(currentSize)
    }

    if (showInterstitial) {
      includeInterstitialToHtml(config.slotId, config.displayTimeInSec)

      googletag.pubads().addEventListener('slotResponseReceived', function (event) {
        let interstitialDiv = document.getElementById(config.containerId)

        if (interstitialDiv && config.slotPath === event.slot.getAdUnitPath()) {
          let response = event.slot.getResponseInformation()
          if (response !== null && response.campaignId != null) {
            if (config.frequencyCap) {
              consoleLog('interstitial', 'Check FreqCap')
              const frequencyCapOkay = checkFrequencyCap(
                config.frequencyCap.type,
                config.frequencyCap.amount,
                'ifq',
                false,
              )
              if (frequencyCapOkay) {
                writeToSessionStorage(config.frequencyCap.type, 'ifq')
                document.getElementById(config.containerId).style.display = 'block'
              } else {
                consoleLog('interstitial', 'Blocked by FreqCap')
              }
            } else {
              document.getElementById(config.containerId).style.display = 'block'
            }
          } else {
            consoleLog('interstitial', 'No Response from GPT!')
            consoleLog('interstitial - Response', response)

            let interstitial = document.getElementById('dlab-close-interstitial')
            if (interstitial) {
              interstitial.innerHTML = ''
            }
          }
        }
      })
    }
  }

  const shouldClose = () => {
    if (config.showInViewports) {
      let enabledSizes = config.showInViewports
      let interstitialCloseButton = document.getElementById('dlab-close-interstitial')

      if (!enabledSizes.includes(size) && interstitialCloseButton) {
        interstitialCloseButton.click()
        googletag.destroySlots([dlab.ads[config.slotId]])
      }
    }
  }

  return {
    show,
    shouldClose,
  }
}

export default interstitial
