import isStolApp from '../../../../modules/is-stolapp'
import queryString from 'query-string'

const version = require('../../../../../package.json').version

const includeHiddenOptionsButton = (hiddenOptions) => {
  function constructGetDataHtml(hiddenOptions) {
    let htmlString = ''
    for (const [category, values] of Object.entries(hiddenOptions)) {
      values['settings'].forEach((entry) => {
        switch (entry.type) {
          case 'switch': {
            htmlString +=
              'document.getElementById("' +
              entry.valueKey +
              '").value = localStorage.getItem("' +
              entry.valueKey +
              '") == "true";' +
              '\n'
            break
          }
          case 'text':
          case 'textarea':
          default: {
            htmlString +=
              'document.getElementById("' +
              entry.valueKey +
              '").value = localStorage.getItem("' +
              entry.valueKey +
              '");' +
              '\n'
            break
          }
        }
      })
    }

    return htmlString
  }

  function constructFormHtml(hiddenOptions) {
    let htmlString = ''

    for (const [category, values] of Object.entries(hiddenOptions)) {
      htmlString += "<div id='" + category + "-panel'>"
      htmlString += '<h4>' + category + '</h4>'
      values['settings'].forEach((entry) => {
        htmlString += "<div id='" + entry.valueKey + "-block'>" + '\n'
        htmlString += "    <label for='" + entry.valueKey + "'>" + entry.label + '</label>' + '\n'

        switch (entry.type) {
          case 'switch': {
            htmlString +=
              '<select onchange="localStorage.setItem(\'' +
              entry.valueKey +
              "', document.getElementById('" +
              entry.valueKey +
              '\').value)" id="' +
              entry.valueKey +
              '" name="' +
              entry.valueKey +
              '" class="select-input">' +
              '\n'
            htmlString += '<option value="false">Deaktiviert</option>' + '\n'
            htmlString += '<option value="true">Aktiviert</option>' + '\n'
            htmlString += '</select>' + '\n'
            break
          }
          case 'text':
          case 'textarea':
          default: {
            htmlString +=
              '<input onkeyup="localStorage.setItem(\'' +
              entry.valueKey +
              "', document.getElementById('" +
              entry.valueKey +
              '\').value)" id="' +
              entry.valueKey +
              '" name="' +
              entry.valueKey +
              '" type="text" class="text-input" placeholder="' +
              entry.label +
              '">\n'

            break
          }
        }

        htmlString += '</div>' + '\n'
      })
      htmlString += '</div><br>'
    }

    return htmlString
  }

  let css =
    'body {\n' +
    '    position: relative;\n' +
    '}\n' +
    '#open-hidden-options-button div {\n' +
    '    height: 50px;\n' +
    '    margin-bottom: 55px;\n' +
    '    background-color: #D41D24;\n' +
    '    border: 3px solid white; \n' +
    '}\n' +
    '#hidden-options-modal {\n' +
    '    display: none; \n' +
    '    position: fixed; \n' +
    '    z-index: 100;\n' +
    '    left: 0;\n' +
    '    top: 0;\n' +
    '    width: 100%;\n' +
    '    height: 100%;\n' +
    '    overflow: auto;\n' +
    '    background-color: rgba(0,0,0,0.4);\n' +
    '    font-size: 16px;\n' +
    '}\n' +
    '#hidden-options-modal #hidden-options-modal-content {\n' +
    '    background-color: #fefefe;' +
    '    margin: 5% auto;' +
    '    padding: 10px;' +
    '    border: 1px solid #888;' +
    '    width: 95%;' +
    '}\n' +
    '#hidden-options-modal #close-hidden-options-modal {\n' +
    '    position: absolute;\n' +
    '    right: 25px;\n' +
    '    top: 10px;\n' +
    '    width: 30px;\n' +
    '    height: 30px;\n' +
    '    border-radius: 15px;\n' +
    '    border: #000000 solid 1px;\n' +
    '    background-color: white;\n' +
    '    cursor: pointer;\n' +
    '}\n' +
    '#hidden-options-modal #close-hidden-options-modal::before {\n' +
    '    content: "✖";\n' +
    '    position: absolute;\n' +
    '    top: 50%;\n' +
    '    left: 50%;\n' +
    '    transform: translate(-50%, -50%);\n' +
    '    line-height: 1em;\n' +
    '}\n' +
    '#hidden-options-modal .text-input, #hidden-options-modal .select-input {' +
    '     width: 100%;\n' +
    '     padding: 6px;\n' +
    '     margin: 8px 0;\n' +
    '     display: inline-block;\n' +
    '     border: 1px solid #ccc;\n' +
    '     border-radius: 4px;\n' +
    '     box-sizing: border-box;\n' +
    '}\n'
  let js =
    'function openHiddenOptions () {' +
    '    console.log("Open Options!");' +
    '    document.getElementById("hidden-options-modal").style.display = "block";' +
    constructGetDataHtml(hiddenOptions) +
    '}' +
    'function closeHiddenOptions () {' +
    '    document.getElementById("hidden-options-button").dataset.clicked = 0;' +
    '    document.getElementById("hidden-options-modal").style.display = "none";' +
    '}'
  let html =
    '<div id="hidden-options-modal">' +
    '    <div id="hidden-options-modal-content">' +
    '        <div id="close-hidden-options-modal" onclick="closeHiddenOptions()"></div>' +
    '        <div>' +
    constructFormHtml(hiddenOptions) +
    '</div>' +
    '    </div>' +
    '</div>' +
    '<div id="open-hidden-options-button" onclick="openHiddenOptions()">' +
    '    <div style="text-align: center;line-height: 50px;color: white;font-family: \'Fira Sans\';font-weight: 700;">DLab Settings - v' +
    version +
    '</div>' +
    '</div>'

  if (!document.getElementById('hidden-options-container')) {
    let containerDiv = document.createElement('div')
    containerDiv.id = 'hidden-options-container'
    document.body.appendChild(containerDiv)

    let style = document.createElement('style')
    style.type = 'text/css'
    style.appendChild(document.createTextNode(css))
    containerDiv.appendChild(style)

    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.appendChild(document.createTextNode(js))
    containerDiv.appendChild(script)

    let div = document.createElement('div')
    div.id = 'hidden-options-button'
    div.innerHTML = html
    document.body.appendChild(div)

    if (!isStolApp() || window.athesia_react.app_env != 'staging') {
      document.getElementById('open-hidden-options-button').style.display = 'none'
    } else {
      var content = document.getElementsByClassName('content')[0]

      if (content) {
        content.style.height = 'unset'
        content.appendChild(div)
      }
    }

    if (queryString.parse(window.location.search).showMenu === 'true') {
      document.getElementById('hidden-options-modal').style.display = 'block'
    }
  }
}

export default includeHiddenOptionsButton
