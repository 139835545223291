import settingsAds from '../../config/settings/ads'
import settingsJobs from '../../config/settings/jobs'
import settingsDefault from '../../config/settings/default'
import settingsApp from '../../config/settings/app'
import settingsCmp from '../../config/settings/cmp'

const settingsSTN = () => ({
  ...settingsDefault(),
  ...settingsAds(),
  ...settingsCmp(),
  ...settingsJobs(),
  ...settingsApp(),
})

export default settingsSTN
