// For full documentation see
// https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration#size-mappings
// import stolConfig from './stol-config'

const changeConfigToStolApp = (stolConfig) => {
  const stolAppConfig = {
    ...stolConfig,

    general: {
      ...stolConfig.general,
      anchorAd: null,
    },
  }

  const h2SlotIdx = stolConfig.slots.map((slot) => slot.id).indexOf('dlab_ad_H2')
  if (h2SlotIdx !== -1) {
    stolAppConfig.slots[h2SlotIdx].sizeMappingName = 'SKY'
  }

  const s2SlotIdx = stolConfig.slots.map((slot) => slot.id).indexOf('dlab_ad_S2')
  if (s2SlotIdx !== -1) {
    stolAppConfig.slots[s2SlotIdx].sizeMappingName = 'SKY'
  }

  return stolAppConfig
}

export default changeConfigToStolApp
