import getParameterByName from '../get-parameter-by-name'
import isServerSide from '../../../../modules/is-server-side'

const isTestTarget = () => {
  var queryAdsTest = getParameterByName('dlabAdsTest')
  if (queryAdsTest === 'on' && !isServerSide()) window.localStorage.setItem('dlabAdsTest', 'on')
  if (queryAdsTest === 'off' && !isServerSide()) window.localStorage.removeItem('dlabAdsTest')
  if (
    (queryAdsTest != null && queryAdsTest !== 'off') ||
    (!isServerSide() && window.localStorage.getItem('dlabAdsTest'))
  ) {
    return true
  }
  return false
}

export default isTestTarget
