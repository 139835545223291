import consoleJs from '../../bundles/modules/console-js'

const settingsApp = () => {
  consoleJs()
  return {
    App: {
      settings: [
        {
          label: 'Js Console aktivieren',
          valueKey: 'dlabConsoleTest',
          type: 'switch',
        },
        {
          label: 'local hosted - Js Console Vorlon aktivieren (https://ddl.serveo.net)',
          valueKey: 'dlabVorlonTest',
          type: 'switch',
        },
      ],
    },
  }
}
export default settingsApp
