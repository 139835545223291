import React from 'react'
import PropTypes from 'prop-types'

const customRulesSlots = (customRules, slotToCheck) => {
  if (!customRules) {
    return 'default'
  }
  let noAds = false
  let excluded = false
  let excludedResult = 'excluded|'
  const currentUrl = location.href
  customRules.forEach((customRule) => {
    if (customRule.slots) {
      let match = customRule.exact ? currentUrl === customRule.url : currentUrl.includes(customRule.url)

      let matchSlot = customRule.inverted
        ? !customRule.slots.includes(slotToCheck)
        : customRule.slots.includes(slotToCheck)

      if (match && matchSlot) {
        if (customRule.noAds) {
          noAds = true
          return
        }

        if (customRule.customExclusionLabels) {
          excluded = true
          excludedResult = excludedResult + customRule.customExclusionLabels
          return
        }
      }
    }
  })

  if (noAds) {
    return 'noads'
  }

  if (excluded) {
    return excludedResult
  }

  return 'default'
}

export default customRulesSlots
