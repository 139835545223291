import consoleLogger from '../../../../modules/console-logger'
import getCookie from '../get-cookie'

const [consoleLog] = consoleLogger('UniversalAdsFunctions')

const addCloseButton = (options) => {
  window.googletag = window.googletag || { cmd: [] }

  var elements = options.elements
  var elementIds = elements[0].ids
  var elementWidth = elements[0].width[0]

  function addCloseButton(event) {
    var elementSlot = event.slot
    var elementId = elementSlot.getSlotElementId()
    var adWidth = event.size ? event.size[0] : []

    var isElementValid =
      elementIds.findIndex(function (requestedElementId) {
        return elementId === requestedElementId
      }) !== -1
    var showCloseButton = isElementValid && adWidth === elementWidth

    var styleLog = 'color: white; border-radius: 3px; padding:2px; font-size: 10px;'
    var styleLogColor = showCloseButton ? 'background: #00AF66;' : 'background: #ED2E38;'
    var dlabDebug = ['%cdlab-close-button: ' + elementId, styleLogColor + styleLog]

    consoleLog(
      'UniversalAdsTest',
      dlabDebug[0],
      dlabDebug[1],
      '___START___',
      showCloseButton ? '___ACTIVE___' : '___INACTIVE___',
    )
    consoleLog('UniversalAdsTest', 'ad width: ' + adWidth)

    var adUnit = elementSlot.getAdUnitPath()
    var portalKey = adUnit.slice(0, adUnit.indexOf('_'))
    var generalKey = adUnit.slice(adUnit.lastIndexOf('_') + 1)
    var adunitKey = portalKey + ':' + generalKey
    var closeButtonID = 'dlabCloseButton-' + adunitKey

    var closeButton = document.getElementById(closeButtonID)

    var element = document.getElementById(elementId)

    if (closeButton) {
      closeButton.remove()
      element.parentNode.style.width = 'unset'
      // nicht fuer SUEN
      if (elementId.includes('_')) {
        element.parentNode.style.margin = 'unset'
        element.parentNode.style.position = 'unset'
      }
    }

    if (showCloseButton) {
      if (element) {
        var closedAdCookies = getCookie('dlabClosedAd')

        if (elementSlot && closedAdCookies.includes(adunitKey)) {
          window.googletag.destroySlots([elementSlot])
        } else if (!event.isEmpty) {
          element.parentNode.style.width = adWidth + 'px'
          // nicht fuer SUEN
          if (elementId.includes('_')) {
            element.parentNode.style.margin = '0 auto'
            element.parentNode.style.position = 'relative'
          }

          if (document.getElementById('styleCloseButton') === null) {
            var style = document.createElement('style')
            style.setAttribute('id', 'styleCloseButton')

            var styleElement = '#' + elementId + ' { position: relative; }'
            var styleCloseButton =
              '.dlabCloseButton { position: absolute; top: -12.5px; left: -12.5px;' +
              'width: 25px; height: 25px; background: grey; font-weight: bold; border-radius: 50%;' +
              'color: white; padding: 0; margin: 0; line-height: 22px; font-size: 19px; text-align: center;' +
              'cursor: pointer; display: block; font-family: "Arial Black", Helvetica, Arial;}'
            style.innerHTML = styleElement + styleCloseButton
            document.head.appendChild(style)
          }
          var newCloseButton = document.createElement('div')
          newCloseButton.setAttribute('id', 'dlabCloseButton-' + adunitKey)
          newCloseButton.setAttribute('class', 'dlabCloseButton')
          newCloseButton.innerHTML = 'x'
          newCloseButton.onclick = function () {
            window.googletag.destroySlots([elementSlot])
            newCloseButton.parentNode.removeChild(newCloseButton)
            if (typeof document !== undefined) {
              var cookiesPrevious = ''
              if (closedAdCookies) cookiesPrevious = closedAdCookies + '|'
              document.cookie = 'dlabClosedAd=' + cookiesPrevious + adunitKey + ';;path=/'
            }
          }
          element.parentNode.appendChild(newCloseButton)
        }
      }
    }
  }

  window.googletag.cmd.push(function () {
    window.googletag.pubads().addEventListener('slotRenderEnded', addCloseButton)
  })
}

const reloader = (options) => {
  var interval = options.interval
  var campaignIds = options.campaignIds
  var adUnitIds = options.adUnitsIds
  var viewPercentage = options.viewPercentage ? options.viewPercentage : 100
  var timers = []

  function reload(optionsReload) {
    var slots = optionsReload.slots
    var track = optionsReload.track
    var slotAdUnitId = optionsReload.slotAdUnitId
    if (window.googletag) {
      window.googletag.pubads().refresh(slots)
      if (track) {
        if (options.onReloadSuccess) {
          options.onReloadSuccess(slotAdUnitId)
        }
        if (window.dataLayer)
          window.dataLayer.push({
            event: 'universalAdReloaded',
            adReloadedAdUnit: slotAdUnitId,
          })
      }
    }
  }

  function stopTimer(slotElementId) {
    clearInterval(timers['interval_reloader_' + slotElementId])
    timers['interval_reloader_' + slotElementId] = null
  }

  function reloader(event) {
    var slotCampaignId =
      event.slot.getResponseInformation() && event.slot.getResponseInformation().campaignId
        ? event.slot.getResponseInformation().campaignId
        : null
    var slotElementId = event.slot.getSlotElementId()
    var slotAdUnitId = event.slot.getAdUnitPath()

    var shouldReload =
      event.slot &&
      (slotCampaignId === null || campaignIds.includes(slotCampaignId)) &&
      (!adUnitIds || adUnitIds.includes(slotAdUnitId))
    //shouldReload = shouldReload && event.inViewPercentage >= viewPercentage;

    var styleLog = 'color: white; border-radius: 3px; padding:2px; font-size: 10px;'
    var styleLogColor = shouldReload ? 'background: #00AF66;' : 'background: #ED2E38;'
    var dlabDebug = ['%cdlab-reloader: ' + slotAdUnitId, styleLogColor + styleLog]

    consoleLog(
      'Reloader',
      '___START___' +
        ' / ' +
        (shouldReload ? '___ACTIVE___' : '___INACTIVE___') +
        ' / ' +
        slotElementId +
        ' / ' +
        event.slot +
        ' / ',
    )
    consoleLog('Reloader', 'slotAdUnitId to reload?: ' + ' / ' + adUnitIds.includes(slotAdUnitId))
    consoleLog(
      'Reloader',
      'campaignId' + ' / ' + slotCampaignId + ' / ' + ' to reload?: ' + ' / ' + campaignIds.includes(slotCampaignId),
    )

    if (
      event.slot &&
      (slotCampaignId === null || campaignIds.includes(slotCampaignId)) &&
      (!adUnitIds || adUnitIds.includes(slotAdUnitId))
    ) {
      //event.slot.getHtml().search(/data-programatic\s*=\s*true/g) !== -1
      if (event.inViewPercentage < viewPercentage) {
        consoleLog('Reloader', ' / ' + 'NOT in View | percentage: ' + ' / ' + event.inViewPercentage)
        stopTimer(slotElementId)
      } else {
        consoleLog('Reloader', ' / ' + 'IN VIEW | percentage: ' + ' / ' + event.inViewPercentage)
        if (typeof timers['interval_reloader_' + slotElementId] !== 'number') {
          timers['interval_reloader_' + slotElementId] = setInterval(function () {
            reload({ slots: [event.slot], track: true, slotAdUnitId: slotAdUnitId })
            consoleLog('Reloader', '~~~RELOADED~~~')
          }, interval * 1000)
        }
      }
    } else {
      stopTimer(slotElementId)
    }
  }

  window.googletag.cmd.push(function () {
    window.googletag.pubads().addEventListener('slotVisibilityChanged', reloader)
  })
}

const recallEmpty = (options) => {
  var exceptAdUnits = options && options.exceptAdUnits ? options.exceptAdUnits : []
  var maxRecalls = options && options.maxRecalls ? options.maxRecalls : 10
  var countRecalls = {}

  function recallEmpty(event) {
    var slotAdUnitId = event.slot.getAdUnitPath()
    if (
      event.isEmpty &&
      !exceptAdUnits.includes(slotAdUnitId) &&
      (!countRecalls[slotAdUnitId] || countRecalls[slotAdUnitId] < maxRecalls)
    ) {
      countRecalls[slotAdUnitId] = countRecalls[slotAdUnitId] ? countRecalls[slotAdUnitId] + 1 : 1
      setTimeout(function () {
        window.googletag.pubads().refresh([event.slot])
      }, 5000)
    }
    if (countRecalls[slotAdUnitId] && countRecalls[slotAdUnitId] === maxRecalls) {
      if (window.dataLayer)
        window.dataLayer.push({
          event: 'universalAdRecallMax',
          adRecallAdUnit: slotAdUnitId,
        })
    }
    if (!event.isEmpty && countRecalls[slotAdUnitId] && countRecalls[slotAdUnitId] < maxRecalls) {
      if (window.dataLayer)
        window.dataLayer.push({
          event: 'universalAdRecalled',
          adRecallAdUnit: slotAdUnitId,
        })
    }
  }

  window.googletag.cmd.push(function () {
    window.googletag.pubads().addEventListener('slotRenderEnded', recallEmpty)
  })
}

export { addCloseButton, reloader, recallEmpty }
