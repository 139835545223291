const settingsCmp = () => ({
  'CMP - Privacy': {
    settings: [
      {
        label: 'Console Logs - Init & Consent Ready',
        valueKey: 'dlabSourcepointTest',
        type: 'switch',
      },
    ],
  },
})

export default settingsCmp
