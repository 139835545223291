import { getStoredItem } from '../../../../../modules/stored-item'
import consoleLogger from '../../../../../modules/console-logger'

const vorlonJs = () => {
  const [consoleLog] = consoleLogger('Console')
  const optionConsole = getStoredItem(`dlabVorlonTest`, true)
  const isActive = optionConsole === 'true'
  consoleLog('is activated: ', isActive)
  if (isActive) {
    ;(function () {
      var s = document.createElement('script')
      s.src = 'https://dlab-re.stol.it/consolejs/vorlon.js'
      document.head.appendChild(s)
    })()
  }
}
export default vorlonJs
