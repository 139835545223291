// For full documentation see
// https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration#size-mappings
// import stolConfig from './stol-config'

const changeConfigToSTNApp = (stnConfig) => {
  const stnAppConfig = {
    ...stnConfig,

    general: {
      ...stnConfig.general,
      anchorAd: null,
    },
  }

  stnAppConfig.slots.push({
    id: 'dlab-ad-H2-tablet',
    path: '/2273514/suedtirolnews/stn3_hp_sky',
    sizeMappingName: 'SKY_Tablet',
  })

  stnAppConfig.slots.push({
    id: 'dlab-ad-S2-tablet',
    path: '/2273514/suedtirolnews/stn3_default_sky',
    sizeMappingName: 'SKY_Tablet',
  })

  return stnAppConfig
}

export default changeConfigToSTNApp
