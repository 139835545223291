import createMediaqueryTag from './modules/create-mediaquery-tag'

const handlerMediaqueryTag = ({ viewports } = {}) => {
  const create = (props) => createMediaqueryTag({ viewports, ...props })

  return {
    create,
  }
}

export default handlerMediaqueryTag
