import { getCookieByName } from '../../../../modules/modify-cookies'
import consoleLogger from '../../../../modules/console-logger'
import isServerSide from '../../../../modules/is-server-side'

const getCookieContent = ({ cookieName, cookieObjectProperty }) => {
  const [consoleLog] = consoleLogger('GetCookieContent')
  if (!cookieName || isServerSide()) {
    return false
  }

  // FOR DEBUG:
  //setCookie('stnews-user', '{"email":"philipp.sorio@abc.it"}')

  const cookieContent = getCookieByName(cookieName)
  consoleLog('GET COOKIE CONTENT', 'Looking for cookie ' + cookieName, 'Ads')

  if (!cookieContent) {
    consoleLog('GET COOKIE CONTENT', 'Cookie ' + cookieName + ' empty or not found!', 'Ads')
    return
  }

  const logMessage = ({ key, value }) => {
    consoleLog('GETCOOKIE CONTENT', 'Cookie ' + key + ' found. Value: ' + value, 'Ads')
  }

  if (cookieObjectProperty) {
    const cookiePropertyContent = JSON.parse(cookieContent)[cookieObjectProperty]
    logMessage({ key: cookieObjectProperty, value: cookiePropertyContent })
    return cookiePropertyContent
  }

  logMessage({ key: cookieName, value: cookieContent })
  return cookieContent
}

export default getCookieContent
