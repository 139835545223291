const prepareViewport = (viewports) => {
  const viewportsArray = Object.entries(viewports)

  const getViewportWidths = (view) => {
    if (!view) {
      return false
    }
    const viewport = viewportsArray.filter((item) => item[0].includes(view))

    return viewport.map(([, [width]]) => width)
  }

  const getMaxWidth = (view) => {
    const viewportWidths = getViewportWidths(view)
    if (viewportWidths.length === 1) {
      return viewportWidths[0]
    }
    if (viewportWidths.length === 0) {
      return false
    }
    return viewportWidths.reduce((previous, current) => (current > previous ? current : previous))
  }

  const getMinWidth = (view) => {
    const viewportWidths = getViewportWidths(view)
    if (viewportWidths.length === 1) {
      return viewportWidths[0]
    }
    if (viewportWidths.length === 0) {
      return false
    }
    return viewportWidths.reduce((previous, current) => (current < previous ? current : previous))
  }

  return {
    getMaxWidth,
    getMinWidth,
  }
}

export default prepareViewport
