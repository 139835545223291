import prepareViewport from './modules/prepare-viewport'

const enrichViewportData = (viewports) => {
  const viewportPreparer = prepareViewport(viewports)
  const maxMobile = viewportPreparer.getMaxWidth('mobile')
  const minTablet = maxMobile && maxMobile + 1
  const minDesktop = viewportPreparer.getMinWidth('desktop')
  const maxTablet = minDesktop && minDesktop - 1

  const viewportsGeneric = {}
  if (maxMobile) {
    viewportsGeneric.mobile = {
      max: maxMobile,
    }
  }
  if (minTablet) {
    viewportsGeneric.tablet = viewportsGeneric.tablet || {}
    viewportsGeneric.tablet.min = minTablet
  }
  if (maxTablet) {
    viewportsGeneric.tablet = viewportsGeneric.tablet || {}
    viewportsGeneric.tablet.max = maxTablet
  }
  if (minDesktop) {
    viewportsGeneric.desktop = {
      min: minDesktop,
    }
  }

  return viewportsGeneric
}

export default enrichViewportData
