const settingsLayout = () => ({
  Layout: {
    settings: [
      {
        label: 'Console Logs - des Standard-Layouts',
        valueKey: 'dlabDefaultLayoutTest',
        type: 'switch',
      },
      {
        label: 'Console Logs - LayoutBuilder',
        valueKey: 'dlabLayoutBuilderTest',
        type: 'switch',
      },
      {
        label: 'Console Logs - ScreenLoader',
        valueKey: 'dlabScreenLoaderTest',
        type: 'switch',
      },
    ],
  },
})

export default settingsLayout
