const settingsPiano = () => ({
  'Piano / Login / User / Rights': {
    settings: [
      {
        label: 'Debug Modus',
        valueKey: 'dlabPianoDebug',
        type: 'switch',
      },
      {
        label: 'src/vendor/Piano/components/ExperiencePiano',
        valueKey: 'dlabExperiencePianoTest',
        type: 'switch',
      },
      {
        label: 'Console Logs - access rights',
        valueKey: 'dlabUseAccessTest',
        type: 'switch',
      },
      {
        label: 'Console Logs - on checkout complete',
        valueKey: 'dlabOnCheckoutCompleteTest',
        type: 'switch',
      },
      {
        label: 'Console Logs - on login success',
        valueKey: 'dlabOnLoginSuccessTest',
        type: 'switch',
      },
      {
        label: 'Console Logs - automatic login on page change',
        valueKey: 'dlabAutomaticLoginTest',
        type: 'switch',
      },
      {
        label: 'Console Logs - login by token',
        valueKey: 'dlabPianoLoginByTokenTest',
        type: 'switch',
      },
      {
        label: 'Console Logs - user data',
        valueKey: 'dlabUserDataTest',
        type: 'switch',
      },
    ],
  },
})

export default settingsPiano
