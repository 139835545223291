import consoleLogger from '../../../../../../modules/console-logger'
import queryString from 'query-string'

const prebidVideoSuccess = ({ adTagUrl, ...props }) => {
  const [consoleLog] = consoleLogger('AdsLog')
  consoleLog('adTagUrl', adTagUrl, 'PrebidVideo')
  consoleLog('props', props, 'PrebidVideo')

  if (!adTagUrl) {
    consoleLog('PrebidVideo', 'No vastURL Response')
    return
  }
  let vastUrlObject = queryString.parse(adTagUrl)
  let custParams = vastUrlObject['cust_params']
  consoleLog('PrebidVideo Custom params', custParams)

  return custParams
}

export default prebidVideoSuccess
