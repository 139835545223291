const settingsJobs = () => ({
  'Job Widget': {
    settings: [
      {
        label: 'Job Widget - Partner Filter - (dolomitenmarkt, karriere-suedtirol, joobz, suedtirolerjobs, look4u)',
        valueKey: 'dlabJobsOrigin',
        type: 'textarea',
      },
    ],
  },
})

export default settingsJobs
