import enrichViewportData from './modules/enrich-viewport-data'
const enrichConfigs = (configs) => {
  if (!configs) {
    return false
  }

  if (!configs.viewports) {
    return configs
  }

  configs.viewportsGeneric = enrichViewportData(configs.viewports)

  return configs
}

export default enrichConfigs
