import { getStoredItem } from '../../../../../modules/stored-item'
import consoleLogger from '../../../../../modules/console-logger'
// Connect over https://remotejs.com/viewer/91564c22-1e75-0eae-91e0-8b9516c6ff5e
const remoteJs = () => {
  const [consoleLog] = consoleLogger('Console')
  const optionConsole = getStoredItem(`dlabConsoleTest`, true)
  const isActive = optionConsole === 'true'
  consoleLog('is activated: ', isActive)
  if (isActive) {
    ;(function () {
      var s = document.createElement('script')
      s.src = 'https://remotejs.com/agent/agent.js'
      s.setAttribute('data-consolejs-channel', '91564c22-1e75-0eae-91e0-8b9516c6ff5e')
      document.head.appendChild(s)
    })()
  }
}
export default remoteJs
