// For full documentation see
// https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration#size-mappings

// Config for Südtirol News
import settingsSTN from '../../../BundleSTNWidgets/settings'
import handlerMediaqueryTag from '../../../../modules/handler-mediaquery-tag'
import getCookieContent from '../../modules/get-cookie-content'
import slcOnLoad from '../../modules/slc-on-load'

const customCss = {
  sky: `
    ${handlerMediaqueryTag().create({ max: 1320, min: 769 })} {
      max-height: 90px;
    }
    ${handlerMediaqueryTag().create({ max: 768 })} {
      max-height: 50px;
    }
  `,
  rec: `
    ${handlerMediaqueryTag().create({ max: 768 })} {
      max-height: 400px;
    }
  `,
}

const stnConfig = {
  // GPT size mapping definitions (object). Ideally there is a sizeMapping for each Slot
  sizeMappings: {
    // Homepage
    SB_Desktop: [
      {
        viewPortSize: [980, 0],
        sizes: [
          [970, 150],
          [970, 90],
          [728, 90],
        ],
      },
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
    ],
    SB_Tablet: [
      {
        viewPortSize: [960, 0],
        sizes: [],
      },
      {
        viewPortSize: [768, 0],
        sizes: [
          [728, 150],
          [728, 90],
        ],
      },
      {
        viewPortSize: [0, 0],
        sizes: [
          [320, 100],
          [320, 50],
          [300, 100],
          [300, 50],
        ],
      },
    ],
    SKY_Desktop: [
      {
        viewPortSize: [1380, 0],
        sizes: [
          [300, 600],
          [160, 600],
        ],
      },
      {
        viewPortSize: [1180, 0],
        sizes: [[160, 600]],
      },
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
    ],
    SKY_Tablet: [
      {
        viewPortSize: [1180, 0],
        sizes: [],
      },
      {
        viewPortSize: [768, 0],
        sizes: [[728, 90]],
      },
      {
        viewPortSize: [0, 0],
        sizes: [
          [320, 50],
          [300, 50],
        ],
      },
    ],
    SKY_REC_Desktop: [
      {
        viewPortSize: [980, 0],
        sizes: [
          [160, 600],
          [120, 600],
          [300, 250],
        ],
      },
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
    ],
    REC_Desktop: [
      {
        viewPortSize: [980, 0],
        sizes: [[300, 250]],
      },
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
    ],
    REC_DesktopTablet: [
      {
        viewPortSize: [768, 0],
        sizes: [[300, 250]],
      },
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
    ],
    REC_TabletMobile: [
      {
        viewPortSize: [980, 0],
        sizes: [],
      },
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]],
      },
    ],
    REC_NoTablet: [
      {
        viewPortSize: [960, 0],
        sizes: [[300, 250]],
      },
      {
        viewPortSize: [768, 0],
        sizes: [],
      },
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]],
      },
    ],
    REC_OnlyDesktop: [
      {
        viewPortSize: [960, 0],
        sizes: [[300, 250]],
      },
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
    ],
    REC_OnlyTablet: [
      {
        viewPortSize: [960, 0],
        sizes: [],
      },
      {
        viewPortSize: [768, 0],
        sizes: [[300, 250]],
      },
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
    ],
    REC_OnlyMobile: [
      {
        viewPortSize: [768, 0],
        sizes: [],
      },
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]],
      },
    ],
    REC: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]],
      },
    ],
    REC_CUS_Desktop: [
      {
        viewPortSize: [980, 0],
        sizes: [
          [300, 250],
          [1, 1],
        ],
      },
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
    ],
    REC_CUS_TabletMobile: [
      {
        viewPortSize: [980, 0],
        sizes: [],
      },
      {
        viewPortSize: [0, 0],
        sizes: [
          [300, 250],
          [1, 1],
        ],
      },
    ],
    LL: [
      {
        viewPortSize: [1020, 0],
        sizes: [
          [970, 90],
          [970, 150],
          [640, 120],
        ],
      },
      {
        viewPortSize: [768, 0],
        sizes: [[728, 90]],
      },
      {
        viewPortSize: [0, 0],
        sizes: [
          [320, 100],
          [320, 50],
          [300, 100],
          [300, 50],
        ],
      },
    ],
    FS: [
      {
        viewPortSize: [1020, 0],
        sizes: [[640, 120]],
      },
      {
        viewPortSize: [960, 0],
        sizes: [],
      },
      {
        viewPortSize: [768, 0],
        sizes: [[728, 90]],
      },
      {
        viewPortSize: [0, 0],
        sizes: [
          [320, 100],
          [320, 50],
          [300, 100],
          [300, 50],
        ],
      },
    ],
    FS_Desktop: [
      {
        viewPortSize: [1020, 0],
        sizes: [[640, 120]],
      },
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
    ],
    WALLPAPER: [
      {
        viewPortSize: [0, 0],
        sizes: [[1920, 1080]],
      },
    ],
    IS: [
      {
        viewPortSize: [960, 0],
        sizes: [],
      },
      {
        viewPortSize: [0, 0],
        sizes: [
          [300, 250],
          [320, 480],
        ],
      },
    ],
  },

  // array of slot configurations
  slots: [
    // Interstitial
    {
      id: 'dlab-ad-IS-web',
      path: '/2273514/suedtirolnews/stn3_web_is',
      sizeMappingName: 'IS',
    },
    // Homepage
    {
      id: 'dlab-ad-H1-desktop',
      globalId: 'superBannerSlot',
      path: '/2273514/suedtirolnews/stn3_hp_sb',
      sizeMappingName: 'SB_Desktop',
    },
    {
      id: 'dlab-ad-H1-tablet',
      path: '/2273514/suedtirolnews/stn3_hp_sb',
      sizeMappingName: 'SB_Tablet',
    },
    {
      id: 'dlab-ad-H2-desktop',
      path: '/2273514/suedtirolnews/stn3_hp_sky',
      sizeMappingName: 'SKY_Desktop',
    },
    {
      id: 'dlab-ad-H3',
      path: '/2273514/suedtirolnews/stn3_hp_rec_1',
      sizeMappingName: 'REC_DesktopTablet',
    },
    {
      id: 'dlab-ad-H3-mobile',
      path: '/2273514/suedtirolnews/stn3_hp_rec_1',
      sizeMappingName: 'REC_OnlyMobile',
    },
    {
      id: 'dlab-ad-H4',
      path: '/2273514/suedtirolnews/stn3_hp_rec_2',
      sizeMappingName: 'REC_OnlyDesktop',
    },
    {
      id: 'dlab-ad-H4-tablet',
      path: '/2273514/suedtirolnews/stn3_hp_rec_2',
      sizeMappingName: 'REC_OnlyTablet',
    },
    {
      id: 'dlab-ad-H4-mobile',
      path: '/2273514/suedtirolnews/stn3_hp_rec_2',
      sizeMappingName: 'REC_OnlyMobile',
    },
    {
      id: 'dlab-ad-H5-desktop',
      path: '/2273514/suedtirolnews/stn3_hp_rec_3',
      sizeMappingName: 'REC_Desktop',
    },
    {
      id: 'dlab-ad-H5-tablet-mobile',
      path: '/2273514/suedtirolnews/stn3_hp_rec_3',
      sizeMappingName: 'REC_TabletMobile',
    },
    {
      id: 'dlab-ad-H6',
      path: '/2273514/suedtirolnews/stn3_hp_fs',
      sizeMappingName: 'FS',
    },
    {
      id: 'dlab-ad-H7-desktop',
      path: '/2273514/suedtirolnews/stn3_hp_rec_4',
      sizeMappingName: 'SKY_REC_Desktop',
    },
    {
      id: 'dlab-ad-H7-tablet',
      path: '/2273514/suedtirolnews/stn3_hp_rec_4',
      sizeMappingName: 'REC_OnlyTablet',
    },
    {
      id: 'dlab-ad-H7-mobile',
      path: '/2273514/suedtirolnews/stn3_hp_rec_4',
      sizeMappingName: 'REC_OnlyMobile',
    },
    {
      id: 'dlab-ad-H8',
      path: '/2273514/suedtirolnews/stn3_hp_rec_5',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H9',
      path: '/2273514/suedtirolnews/stn3_hp_rec_6',
      sizeMappingName: 'REC_NoTablet',
    },
    {
      id: 'dlab-ad-H9-tablet',
      path: '/2273514/suedtirolnews/stn3_hp_rec_6',
      sizeMappingName: 'REC_OnlyTablet',
    },
    {
      id: 'dlab-ad-H10',
      path: '/2273514/suedtirolnews/stn3_hp_rec_7',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H11',
      path: '/2273514/suedtirolnews/stn3_hp_rec_8',
      sizeMappingName: 'REC_NoTablet',
    },
    {
      id: 'dlab-ad-H11-tablet',
      path: '/2273514/suedtirolnews/stn3_hp_rec_8',
      sizeMappingName: 'REC_OnlyTablet',
    },
    {
      id: 'dlab-ad-H12',
      path: '/2273514/suedtirolnews/stn3_hp_rec_9',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H13',
      path: '/2273514/suedtirolnews/stn3_hp_rec_10',
      sizeMappingName: 'REC_NoTablet',
    },
    {
      id: 'dlab-ad-H13-tablet',
      path: '/2273514/suedtirolnews/stn3_hp_rec_10',
      sizeMappingName: 'REC_OnlyTablet',
    },
    {
      id: 'dlab-ad-H14',
      path: '/2273514/suedtirolnews/stn3_hp_rec_11',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H15-tablet',
      path: '/2273514/suedtirolnews/stn3_hp_rec_12',
      sizeMappingName: 'REC_OnlyTablet',
    },
    {
      id: 'dlab-ad-WALLPAPER',
      path: '/2273514/suedtirolnews/stn3_wallpaper',
      sizeMappingName: 'WALLPAPER',
    },

    // Default
    {
      id: 'dlab-ad-S1-desktop',
      globalId: 'superBannerSlot',
      path: '/2273514/suedtirolnews/stn3_default_sb',
      sizeMappingName: 'SB_Desktop',
    },
    {
      id: 'dlab-ad-S1-tablet',
      path: '/2273514/suedtirolnews/stn3_default_sb',
      sizeMappingName: 'SB_Tablet',
    },
    {
      id: 'dlab-ad-S2-desktop',
      path: '/2273514/suedtirolnews/stn3_default_sky',
      sizeMappingName: 'SKY_Desktop',
    },
    {
      id: 'dlab-ad-S4',
      path: '/2273514/suedtirolnews/stn3_default_rec_1',
      sizeMappingName: 'REC',
      customCss: customCss.rec,
    },
    {
      id: 'dlab-ad-S4-desktop',
      path: '/2273514/suedtirolnews/stn3_default_rec_1',
      sizeMappingName: 'REC_CUS_Desktop',
    },
    {
      id: 'dlab-ad-S4-tablet',
      path: '/2273514/suedtirolnews/stn3_default_rec_1',
      sizeMappingName: 'REC_CUS_TabletMobile',
      customCss: customCss.rec,
    },
    {
      id: 'dlab-ad-S5-desktop',
      path: '/2273514/suedtirolnews/stn3_default_rec_2',
      sizeMappingName: 'REC_Desktop',
    },
    {
      id: 'dlab-ad-S5',
      path: '/2273514/suedtirolnews/stn3_default_rec_2',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-S5-tablet-mobile',
      path: '/2273514/suedtirolnews/stn3_default_rec_2',
      sizeMappingName: 'REC_TabletMobile',
    },
    {
      id: 'dlab-ad-S11',
      path: '/2273514/suedtirolnews/stn3_default_rec_3',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-S6-desktop',
      path: '/2273514/suedtirolnews/stn3_default_fs_1',
      sizeMappingName: 'FS_Desktop',
    },
    {
      id: 'dlab-ad-S6',
      path: '/2273514/suedtirolnews/stn3_default_fs_1',
      sizeMappingName: 'FS',
    },
    {
      id: 'dlab-ad-S7',
      path: '/2273514/suedtirolnews/stn3_default_fs_2',
      sizeMappingName: 'FS',
    },
    {
      id: 'dlab-ad-S8',
      path: '/2273514/suedtirolnews/stn3_default_fs_3',
      sizeMappingName: 'FS',
    },
    {
      id: 'dlab-ad-S9',
      path: '/2273514/suedtirolnews/stn3_default_fs_4',
      sizeMappingName: 'FS',
    },
    {
      id: 'dlab-ad-S10',
      path: '/2273514/suedtirolnews/stn3_default_fs_5',
      sizeMappingName: 'FS',
    },
    {
      id: 'dlab-ad-S12',
      path: '/2273514/suedtirolnews/stn3_default_ll_1',
      sizeMappingName: 'LL',
    },
  ],

  general: {
    hiddenOptions: settingsSTN(),
    reloadInterval: true,
    collapseEmptyDivs: true,
    disableInitialLoad: true,
    enableSingleRequest: true,
    disableAddScrollbarWidth: true,
    // // Lazy fetching in SRA only works if all slots are outside the fetching margin.
    // lazyLoading: {
    //   fetchMarginPercent: 150,
    //   renderMarginPercent: 150,
    //   mobileScaling: 1.2,
    // },
    userData: {
      email: () => getCookieContent({ cookieName: 'stnews-user', cookieObjectProperty: 'email' }),
    },
    lazyLoad: {
      offset: 500,
    },
    prebid: {
      active: true,
      setSizeMapping: true,
      liveramp: true,
      ortb2UserData: {
        bidders: ['criteo'],
        source: 'https://www.suedtirolnews.it',
      },
      fledgeConfigPlayload: {
        paapi: {
          enabled: true,
          gpt: {
            autoconfig: false,
          },
          defaultForSlots: 1,
          bidders: ['criteo' /* other bidders */],
        },
      },
    },
    adBlockingRecovery: {
      frequencyCap: {
        type: 'time',
        amount: 10,
      },
    },
    amazontam: true,
    preloadedFiles: [
      {
        link: 'https://connect.suedtirol.live/slc.js',
        async: true,
        onload: () => {
          slcOnLoad({
            id: 'SLC-MOT7XPTV',
            enableTargets: true,
            prefixUserId: 'STN',
          })
          window.addEventListener('message', function (event) {
            if (!event.origin.endsWith('.pinpoll.com') || !event.data || event.data.event != 'vote') {
              return
            }
            console.log('dbg_DT')
            if (event.data && event.data.audiences) {
              console.log('dbg_DT_A')
              const interestIds = event.data.audiences.interests.map((interest) => interest.id)
              if (interestIds.length > 0) {
                console.log('dbg_DT_A_SND')
                SLC('pageEvent', 'pinpoll', 'vote', '', '', JSON.stringify(interestIds))
              }
            }
          })
        },
      },
      {
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
      {
        link: 'https://launchpad-wrapper.privacymanager.io/8635aa92-be83-46e4-945a-975b0f76a243/launchpad-liveramp.js',
        async: true,
      },
      {
        link: 'https://cdn.pinpoll.com/sdk.js',
        async: false,
        onload: () => {
          const sdk = new PinpollSdk()
          if (sdk) {
            const options = {
              tree: false,
              removeRootLevel: 'properties',
              onlyLabels: 'de',
            }
            sdk
              .getAudiences(options)
              .then(function (audiences) {
                if (audiences && audiences.interests) {
                  let escapedInterests = []
                  audiences.interests.forEach((int) => {
                    const newInt = int.replace(',', '')
                    escapedInterests.push(newInt)
                  })
                  // Make sure that googletag.cmd exists.
                  window.googletag = window.googletag || {}
                  googletag.cmd = googletag.cmd || []
                  googletag.cmd.push(() => {
                    googletag.pubads().setTargeting('stn_dmp_interests', escapedInterests)
                  })
                }
              })
              .catch(function (error) {
                console.error(error)
              })
          }
        },
      },
    ],
    anchorAd: {
      path: '/2273514/suedtirolnews/stn3_<LOCATION>_sky',
      position: 'bottom',
      maxWidth: [1180, 0],
    },
    interstitial: {
      // [ToDo]: Refactor that shit
      // showInViewports: ['mobile', 'mobile_s', 'tablet', 'tablet_s']
      containerId: 'dlab-interstitial',
      slotId: 'dlab-ad-IS-web',
      ga4eventName: 'web-interstitial',
      slotPath: '/2273514/suedtirolnews/stn3_web_is',
      displayTimeInSec: 15,
    },
    /*reloader: {
      interval: 30,
      campaignIds: [293423654, 164280974],
      adUnitsIds: ['/2273514/suedtirolnews/stn3_default_sky', '/2273514/suedtirolnews/stn3_hp_sky'],
    },*/
  },
}

export default stnConfig
