const includeInterstitialToHtml = (divId, timeoutInSec = 10) => {
  let css =
    '#dlab-interstitial {\n' +
    '    background-color: rgba(0, 0, 0, 0.7);\n' +
    '    display: none;\n' +
    '    position: fixed;\n' +
    '    width: 100vw;\n' +
    '    height: 100vh;\n' +
    '    left: 0;\n' +
    '    top: 0;\n' +
    '    z-index: 9999999;\n' +
    '}\n' +
    '#dlab-interstitial .dlab-content {\n' +
    '    position: absolute;\n' +
    '    top: 50%;\n' +
    '    left: 50%;\n' +
    '    transform: translate(-50%, -50%);\n' +
    '    z-index: 100;\n' +
    '}\n' +
    '#dlab-interstitial #dlab-close-interstitial {\n' +
    '    position: absolute;\n' +
    '    right: 25px;\n' +
    '    top: 10px;\n' +
    '    width: 30px;\n' +
    '    height: 30px;\n' +
    '    border-radius: 15px;\n' +
    '    border: #000000 solid 1px;\n' +
    '    background-color: white;\n' +
    '    cursor: pointer;\n' +
    '    z-index: 99999999;' +
    '}\n' +
    '#dlab-interstitial #dlab-close-interstitial::before {\n' +
    '    content: "✖";\n' +
    '    position: absolute;\n' +
    '    top: 50%;\n' +
    '    left: 50%;\n' +
    '    transform: translate(-50%, -50%);\n' +
    '    line-height: 1em;\n' +
    '}'

  let js =
    'setTimeout(function () {' +
    '    var closeBtn = document.getElementById("dlab-close-interstitial");' +
    '    if (closeBtn) {' +
    '        document.getElementById("dlab-close-interstitial").click();' +
    '    }' +
    '}, ' +
    timeoutInSec +
    '000);'

  let html =
    '' +
    '<div id="dlab-close-interstitial" onclick="this.parentNode.parentNode.removeChild(this.parentNode);return false;" ></div>' +
    '<div class="dlab-content" id=\'' +
    divId +
    "'></div>\n"

  let head = document.head || document.getElementsByTagName('head')[0]
  let style = document.createElement('style')
  let script = document.createElement('script')

  head.appendChild(style)
  head.appendChild(script)

  style.type = 'text/css'
  style.appendChild(document.createTextNode(css))

  script.type = 'text/javascript'
  script.appendChild(document.createTextNode(js))

  let div = document.createElement('div')
  div.id = 'dlab-interstitial'
  div.innerHTML = html
  document.body.appendChild(div)
}

export default includeInterstitialToHtml
