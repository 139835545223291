import consoleLogger from '../../../../modules/console-logger'
import slcGetTargets from '../slc-get-targets'
import { createSessionIDAndHashedMail } from '../hashed-emails'

const [consoleLog] = consoleLogger('AdsLog')
const slcOnLoad = ({ id, enableTargets = false, prefixUserId }) => {
  consoleLog('SLC', 'loaded', 'SLC')

  const ids = createSessionIDAndHashedMail({ prefix: prefixUserId })
  SLC('ui', ids.sessionId)
  // SLC('uui', window.localStorage.getItem('sessionUuid'))

  const userEmail = dlab?.user?.email

  if (userEmail) {
    const hash = userEmail.md5?.toString()
    consoleLog('SLC Hashed Emails: ', userEmail.md5?.toString(), 'SLC')
    SLC('uie', hash)
  }

  // Setup *AFTER* any params and data to SLC
  SLC('setup', id)

  if (enableTargets) {
    slcGetTargets()
  }
}

export default slcOnLoad
