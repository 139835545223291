const settingsArticle = () => ({
  Artikel: {
    settings: [
      {
        label: 'Console Logs - Embed Pinpoll',
        valueKey: 'dlabPinpollTest',
        type: 'switch',
      },
    ],
  },
})

export default settingsArticle
