const getCookieByName = (cookieName) => {
  let name = cookieName + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function setCookie(cookieName, cookieValue, daysValid) {
  const dateToExpire = new Date()
  dateToExpire.setTime(dateToExpire.getTime() + daysValid * 24 * 60 * 60 * 1000)
  let cookieExpires = 'expires=' + dateToExpire.toUTCString()
  document.cookie = cookieName + '=' + cookieValue + ';' + cookieExpires + ';path=/'
}

export { getCookieByName, setCookie }
