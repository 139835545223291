import isServerSide from '../../../../modules/is-server-side'

const pinpollConfig = {
  sizeMappings: {
    BUTTON: [
      {
        viewPortSize: [0, 0],
        sizes: [
          [300, 50],
          [300, 75],
          [300, 100],
          [320, 50],
          [320, 100],
        ],
      },
    ],
    REC: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 250]],
      },
    ],
  },
  slots: [
    {
      id: 'dynamic-ad-container',
      globalId: 'pinpoll_1',
      path: '/2273514/fa_pinpoll_1',
      sizeMappingName: 'REC',
    },
    {
      id: 'dynamic-ad-container-rec-1',
      globalId: 'pinpoll_1',
      path: '/2273514/fa_pinpoll_1',
      sizeMappingName: 'REC',
    },
    {
      id: 'dynamic-ad-container-but-1',
      globalId: 'pinpoll_2',
      path: '/2273514/fa_pinpoll_2',
      sizeMappingName: 'BUTTON',
    },
  ],
  general: {
    reloadInterval: true,
    collapseEmptyDivs: true,
    disableInitialLoad: true,
    enableSingleRequest: true,
    setPageUrl: (() => {
      if (!isServerSide() && window.name) {
        return window.name
      }
      return false
    })(),
    preloadedFiles: [
      {
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
    ],
    prebid: {
      active: true,
      wrapper: (() => {
        if (!isServerSide() && window.name) {
          if (window.name.includes('suedtirolnews.it')) {
            return 'SUEN'
          }
          if (window.name.includes('stol.it')) {
            return 'STOL'
          }
          if (window.name.includes('kicker.de')) {
            return 'KICKER'
          }
          if (window.name.includes('laola1.at')) {
            return 'LAOLA1'
          }
          if (window.name.includes('oe24.at')) {
            return 'OE24'
          }
          if (window.name.includes('pnp.de')) {
            return 'PNP'
          }
          if (window.name.includes('donaukurier.de')) {
            return 'DONAUKURIER'
          }
          return false
        }
        return false
      })(),
    },
    // amazontam: true,
  },
}

export default pinpollConfig
