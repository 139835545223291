import uniqueArrayOfArrays from '../unique-array-of-arrays'
import consoleLogger from '../../../../modules/console-logger'
import handlerWindowDlab from '../handler-window-dlab'
import customRulesSlots from '../custom-rules-slots'

const defineSlots = ({
  googletag,
  sizeMappings,
  disableAddScrollbarWidth,
  customRules,
  forSlot = () => {},
  forSlotSizeMapping = () => {},
}) => {
  const [consoleLog] = consoleLogger('AdsLog')
  const scrollbarWidth = window.innerWidth - document.body.offsetWidth

  const init = (slots) => {
    const { dlabOption } = handlerWindowDlab('ads', { sub: 'units' })
    let definedSlots = []

    slots.forEach((slot) => {
      consoleLog(`div ${slot.id}`, document.getElementById(slot.id), 'Ads')
      if (!slot.inject && !document.getElementById(slot.id)) {
        return
      }

      const slotOk = forSlot(slot)

      if (slotOk === false) {
        return
      }
      try {
        const sizeMappingsForSlot = sizeMappings[slot.sizeMappingName]

        let allSizes = []
        sizeMappingsForSlot.forEach((sizeMappingForSlot) => {
          allSizes.push(sizeMappingForSlot.sizes)
        })
        let flatAllSizes = allSizes.reduce((acc, val) => acc.concat(val), [])
        let distinctSizes = uniqueArrayOfArrays(flatAllSizes)

        let mapping = googletag.sizeMapping()
        sizeMappingsForSlot.forEach((sizeMappingForSlot) => {
          const scrollbarViewPortSize = [...sizeMappingForSlot.viewPortSize]
          scrollbarViewPortSize[0] = scrollbarViewPortSize[0] > 0 ? scrollbarViewPortSize[0] - scrollbarWidth : 0
          const viewportSize = disableAddScrollbarWidth ? sizeMappingForSlot.viewPortSize : scrollbarViewPortSize
          mapping.addSize(viewportSize, sizeMappingForSlot.sizes)
          forSlotSizeMapping({ slot, viewportSize, sizeMappings: sizeMappingForSlot })
        })
        mapping = mapping.build()

        let customRulesSlotResult = customRulesSlots(customRules, slot.id)
        switch (customRulesSlotResult.split('|')[0]) {
          case 'noads':
            break
          case 'excluded':
            let excludedArray = customRulesSlotResult.split('|')[1].split(',')
            const newExcludedSlot = googletag.defineSlot(slot.path, distinctSizes, slot.id).defineSizeMapping(mapping)
            excludedArray.forEach((excluded) => {
              newExcludedSlot.setCategoryExclusion(excluded)
            })
            newExcludedSlot.addService(googletag.pubads())

            if (slot.globalId) {
              window[slot.globalId] = newExcludedSlot
            }
            dlabOption[slot.id] = newExcludedSlot
            definedSlots.push(newExcludedSlot)

            googletag.display(slot.id)
            break
          default:
            const newSlot = googletag
              .defineSlot(slot.path, distinctSizes, slot.id)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())

            if (slot.globalId) {
              window[slot.globalId] = newSlot
            }
            dlabOption[slot.id] = newSlot
            definedSlots.push(newSlot)

            googletag.display(slot.id)
            break
        }
      } catch (err) {
        consoleLog(`div ${slot.id}`, err, 'Ads')
        return
      }
    })

    return definedSlots
  }

  return {
    init,
  }
}

export default defineSlots
