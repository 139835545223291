import consoleLogger from '../../../../modules/console-logger'

const amazonExecuteParallelAuctionAlongsidePrebid = () => {
  const [consoleLog] = consoleLogger('AdsLog')

  const init = ({ prebidInit, slots }) => {
    {
      /** Executes a parallel auction with prebid **/
      const FAILSAFE_TIMEOUT = 10000
      const requestManager = {
        adserverRequestSent: false,
        aps: false,
        prebid: false,
      }
      consoleLog('AEPA - init status', requestManager, 'Ads')
      consoleLog('AEPA - slots', slots, 'Ads')
      // when both APS and Prebid have returned, initiate ad request
      function biddersBack() {
        consoleLog('AEPA - AMAZON TAM - ready?', requestManager.aps, 'Ads')
        consoleLog('AEPA - PREBID - ready?', requestManager.prebid, 'Ads')
        if (requestManager.aps && requestManager.prebid) {
          consoleLog('AEPA - AMAZON-TAM and PREBID', 'ready', 'Ads')
          sendAdserverRequest()
        }
        return
      }

      // sends adserver request
      function sendAdserverRequest() {
        if (requestManager.adserverRequestSent === true) {
          consoleLog('AEPA', 'ad server request ALREADY sent', 'Ads')
          return
        }
        requestManager.adserverRequestSent = true
        googletag.cmd.push(function () {
          consoleLog('AEPA', 'refresh ads', 'Ads')
          consoleLog('AEPA refresh slots', slots, 'Ads')
          googletag.pubads().refresh(slots)
        })
      }

      // sends bid request to APS and Prebid
      function requestHeaderBids() {
        consoleLog('AEPA AMAZON-TAM window variable "apstag"', apstag, 'Ads')
        consoleLog('AEPA AMAZON-TAM slots"', slots, 'Ads')
        // APS request
        apstag.fetchBids(
          {
            timeout: 3000,
          },
          function (bids) {
            consoleLog('AEPA AMAZON-TAM bids', bids, 'Ads')
            googletag.cmd.push(function () {
              apstag.setDisplayBids()
              requestManager.aps = true // signals that APS request has completed
              biddersBack() // checks whether both APS and Prebid have returned
            })
          },
        )

        /*
        const optionsPrebid = {
          bidsBackHandler: function () {
            googletag.cmd.push(function () {
              requestManager.prebid = true; // signals that Prebid request has completed
              biddersBack(); // checks whether both APS and Prebid have returned
            })
          }
        }

         */
        const onPrebidSuccess = () => {
          requestManager.prebid = true // signals that Prebid request has completed
          biddersBack() // checks whether both APS and Prebid have returned
        }

        prebidInit({ slots, adsRefresh: false, onSuccess: onPrebidSuccess })
      }

      // initiate bid request
      requestHeaderBids()

      // set failsafe timeout
      window.setTimeout(function () {
        consoleLog('AEPA - Failsafe', 'STARTED', 'Ads')
        sendAdserverRequest()
      }, FAILSAFE_TIMEOUT)
    }
  }

  return {
    init,
  }
}

export default amazonExecuteParallelAuctionAlongsidePrebid
