import handlerMediaqueryTag from '../../../../../../modules/handler-mediaquery-tag'

const viewportsCustomCss = (customCss) => {
  if (typeof customCss !== 'object') {
    return false
  }
  const viewportCustomCss = Object.entries(customCss)
  let slotElementCss = ''
  viewportCustomCss.forEach(([viewport, css]) => {
    slotElementCss += `
        ${handlerMediaqueryTag().create({ viewport })} {
          ${css}
        }
      `
  })

  return slotElementCss
}

export default viewportsCustomCss
