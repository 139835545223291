import consoleLogger from '../../../../modules/console-logger'
import targets from '../targets'
import handlerWindowDlab from '../handler-window-dlab'

const [consoleLog] = consoleLogger('AdsLog')
const { dlabOption } = handlerWindowDlab('ads', { sub: 'SLC' })
const slcSetTargets = ({ googletag }) => {
  if (dlabOption['targetsSet'] === true) {
    consoleLog('SLCTargets already set: ', dlabOption['targetsSet'], 'SLC')
    return
  }
  const adTargets = []
  const slcTargetsString = window.localStorage.getItem('SLCTargets')
  consoleLog('Saved SLCTargets String', slcTargetsString, 'SLC')
  if (slcTargetsString) {
    const slcTargets = JSON.parse(slcTargetsString)
    consoleLog('Parsed SLCTargets', slcTargets, 'SLC')
    Object.keys(slcTargets).forEach((slcTargetKey) => {
      adTargets[slcTargetKey] = slcTargets[slcTargetKey]
    })
    if (adTargets.length > 0) {
      dlabOption['targets'] = adTargets
      dlabOption['targetsSet'] = true
    }
  }
  targets({
    googletag,
    adTargets,
  })
}

const clearSLCTargets = () => {
  dlabOption['targetsSet'] = false
}

export default slcSetTargets

export { clearSLCTargets }
