import isServerSide from '../../../../modules/is-server-side'

const handlerWindowDlab = (main, { sub } = {}) => {
  if (isServerSide()) {
    return {}
  }
  window.dlab = window.dlab || {}
  window.dlab[main] = window.dlab[main] || {}
  let dlabOption = window.dlab[main]
  if (sub) {
    window.dlab[main][sub] = window.dlab[main][sub] || {}
    dlabOption = window.dlab[main][sub]
  }

  const importDlabOption = (options) => {
    if (sub) {
      window.dlab[main][sub] = options
      return
    }
    window.dlab[main] = options
  }

  const clearDlabOption = () => {
    importDlabOption({})
  }

  return { dlabOption, clearDlabOption, importDlabOption }
}

export default handlerWindowDlab
