import checkDisplay from '../check-display'
import handlerWindowDlab from '../handler-window-dlab'

const responsiveRefresh = ({
  googletag = window.googletag,
  onResize = () => {},
  beforeResize = () => {},
  viewportsConfig,
  generalConfigs,
  autoRefresh = true,
}) => {
  let size = checkDisplay(viewportsConfig)
  const { dlabOption } = handlerWindowDlab('ads', { sub: 'resize' })

  if (!dlabOption['isInitiated']) {
    window.addEventListener('resize', function () {
      const newSize = checkDisplay(viewportsConfig)
      if (size != newSize) {
        size = newSize

        beforeResize()

        if (autoRefresh) {
          googletag.pubads().refresh()
        }
        onResize()
      }
    })
  }
  dlabOption['isInitiated'] = true
}

export default responsiveRefresh
