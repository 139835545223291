const viewports = {
  all: [0, 0],
  desktop: [768, 0],
}

const sizes = {
  superBanner: [970, 150],
  smallSuperBanner: [320, 100],
  rectangle: [300, 250],
}

const secondHandConfig = {
  sizeMappings: {
    SUPERBANNER: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.smallSuperBanner],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.superBanner],
      },
    ],
    REC: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.rectangle],
      },
    ],
  },

  slots: [
    {
      id: 'div-gpt-ad-second-hand_hp_sb',
      path: '/2273514/second-hand_hp_sb',
      sizeMappingName: 'SUPERBANNER',
    },
    {
      id: 'div-gpt-ad-second-hand_hp_rec1',
      path: '/2273514/second-hand_hp_rec1',
      sizeMappingName: 'REC',
    },
    {
      id: 'div-gpt-ad-second-hand_hp_rec2',
      path: '/2273514/second-hand_hp_rec2',
      sizeMappingName: 'REC',
    },

    {
      id: 'div-gpt-ad-second-hand_cat_sb',
      path: '/2273514/second-hand_cat_sb',
      sizeMappingName: 'SUPERBANNER',
    },
    {
      id: 'div-gpt-ad-second-hand_cat_rec1',
      path: '/2273514/second-hand_cat_rec1',
      sizeMappingName: 'REC',
    },
    {
      id: 'div-gpt-ad-second-hand_cat_rec2',
      path: '/2273514/second-hand_cat_rec2',
      sizeMappingName: 'REC',
    },

    {
      id: 'div-gpt-ad-second-hand_default_sb',
      path: '/2273514/second-hand_default_sb',
      sizeMappingName: 'SUPERBANNER',
    },
    {
      id: 'div-gpt-ad-second-hand_default_rec1',
      path: '/2273514/second-hand_default_rec1',
      sizeMappingName: 'REC',
    },
  ],

  general: {
    reloadInterval: true,
    collapseEmptyDivs: true,
    disableInitialLoad: true,
    enableSingleRequest: true,
    lazyLoad: {
      offset: 500,
    },
    prebid: {
      active: true,
      fledgeConfigPlayload: {
        paapi: {
          enabled: true,
          gpt: {
            autoconfig: false,
          },
          defaultForSlots: 1,
          bidders: ['criteo' /* other bidders */],
        },
      },
    },
    adBlockingRecovery: {
      frequencyCap: {
        type: 'time',
        amount: 10,
      },
    },

    preloadedFiles: [
      {
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
    ],
  },
}

export default secondHandConfig
