import handlerWindowDlab from '../handler-window-dlab'

const checkDisplay = (viewports) => {
  const { dlabOption } = handlerWindowDlab('ads', { sub: 'resize' })
  const windowWidth = window.innerWidth
  let display

  for (const viewport in viewports) {
    let viewportWidth = viewports[viewport]
    if (Array.isArray(viewportWidth)) {
      ;[viewportWidth] = viewportWidth
    }
    if (windowWidth >= parseInt(viewportWidth)) {
      display = viewport
      break
    }
  }

  dlabOption['view'] = display

  return display
}

export default checkDisplay
