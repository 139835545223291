import consoleLogger from '../../../../../../modules/console-logger'
import handlerWindowDlab from '../../../../modules/handler-window-dlab'

const liveRamp = ({ config }) => {
  const [consoleLog] = consoleLogger('AdsLog')
  const { dlabOption } = handlerWindowDlab('prebid', { sub: 'liveRamp' })
  if (!config) {
    return
  }
  if (dlabOption['isInitiated']) {
    return
  }
  const email = dlab.user?.email

  consoleLog('PREBID Liveramp - hashed email', email?.md5?.toString(), 'Ads')
  if (!email) {
    consoleLog('PREBID Liveramp - No email found.', 'Ads')
    return
  }

  window.addEventListener('envelopeModuleReady', function (event) {
    consoleLog('PREBID Liveramp - event', event, 'Ads')
    atsenvelopemodule.setAdditionalData({
      type: 'emailHashes',
      id: [email.sha1.toString(), email.sha256.toString(), email.md5.toString()],
    })
  })
  dlabOption['isInitiated'] = true
}

export default liveRamp
