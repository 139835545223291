import handlerWindowDlab from '../../../../bundles/BundleAds/modules/handler-window-dlab'

const createMediaQueryTag = ({ viewports, viewport, max, min }) => {
  const { dlabOption } = handlerWindowDlab('ads')
  const defaultViewports = viewports || dlabOption?.config?.viewports || {}
  const isViewType = (viewType) => Object.keys(defaultViewports).includes(viewType)

  const genericViewPorts = dlabOption?.config?.viewportsGeneric || {}
  const isGenericViewType = Object.keys(genericViewPorts).includes(viewport)

  let maxWidth = max
  let minWidth = min

  if (isViewType(max)) {
    maxWidth = defaultViewports[max][0] - 1
  }
  if (isViewType(min)) {
    minWidth = defaultViewports[min][0] + 1
  }

  if (isGenericViewType) {
    switch (viewport) {
      case 'mobile':
        maxWidth = genericViewPorts.mobile.max
        break
      case 'tablet':
        maxWidth = genericViewPorts.tablet.max
        minWidth = genericViewPorts.tablet.min
        break
      case 'desktop':
        minWidth = genericViewPorts.desktop.min
        break
    }
  }

  if (maxWidth && !minWidth) {
    return `@media screen and (max-width: ${maxWidth}px)`
  }
  if (minWidth && maxWidth) {
    return `@media screen and (max-width: ${maxWidth}px) and (min-width: ${minWidth}px)`
  }
  if (minWidth && !maxWidth) {
    return `@media screen and (min-width: ${minWidth}px)`
  }
}

export default createMediaQueryTag
