import { injectHtml } from '../../../../modules/interact-with-html'
import consoleLogger from '../../../../modules/console-logger'

const initAdsInjectAd = (slot) => {
  const [consoleLog] = consoleLogger('AdsInject')
  consoleLog('Init inject', slot.id, 'AdsLog')
  if (!slot && !slot.id && !slot.inject && !slot.inject.selector) {
    consoleLog('No slot or inject options', slot, 'AdsLog')
    return false
  }
  const injectElement = document.querySelector(slot.inject.selector)
  if (!injectElement) {
    consoleLog('No inject element found', slot.inject.selector, 'AdsLog')
    return false
  }
  if (slot.inject?.paths && Array.isArray(slot.inject.paths)) {
    if (!slot.inject.paths.includes(location.pathname)) {
      consoleLog('Url not fit for inject', slot.inject.paths, 'AdsLog')
      return false
    }
  }
  let customReferenceNode
  if (slot.inject?.selectorsPosition) {
    customReferenceNode = document.querySelectorAll(slot.inject.selector)[slot.inject.selectorsPosition - 1]

    if (!customReferenceNode) {
      consoleLog('No custom reference node found', slot.inject.selectorsPosition, 'AdsLog')
      return false
    }
  }

  const childNode = document.createElement('div')
  childNode.id = slot.id
  childNode.style.cssText = slot.inject?.styleAd
  const parentNode = document.createElement('div')
  parentNode.style.cssText = slot.inject?.styleParent
  parentNode.appendChild(childNode)
  injectHtml({
    injectPosition: slot.inject?.position,
    referenceSelector: slot.inject?.selector,
    customReferenceNode: customReferenceNode,
    newNode: parentNode,
    consoleLoggerId: 'initAdsInjectAd',
  })
  consoleLog('Ad injected SUCCESS', { id: slot?.id, inject: slot?.inject }, 'AdsLog')
}

const triggerLazyLoadElement = (slot) => {
  if (slot.inject && slot.inject.selector) {
    return document.querySelector(slot.inject.selector)
  }
  return false
}

export default initAdsInjectAd

export { triggerLazyLoadElement }
