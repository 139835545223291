import { addCloseButton } from '../universal-ads-functions'

const optionsResize = ({ generalConfigs, lazyLoadAds }) => {
  const onResize = () => {
    if (generalConfigs.addCloseButton) {
      addCloseButton(generalConfigs.addCloseButton, true)
    }
    if (generalConfigs.lazyLoad) {
      lazyLoadAds.refreshSlots({ clear: true })
    }

    if (generalConfigs && generalConfigs.anchorAd && generalConfigs.anchorAd.maxWidth) {
      const windowWidth = window.innerWidth
      const anchorMaxWidth = Array.isArray(generalConfigs.anchorAd.maxWidth)
        ? generalConfigs.anchorAd.maxWidth[0]
        : generalConfigs.anchorAd.maxWidth
      const anchorPath =
        location.pathname === '/'
          ? generalConfigs.anchorAd.path.replace('<LOCATION>', 'hp')
          : generalConfigs.anchorAd.path.replace('<LOCATION>', 'default')

      if (windowWidth > anchorMaxWidth) {
        document.getElementById('gpt_unit_' + anchorPath + '_0').style.display = 'none'
      }
      if (windowWidth <= anchorMaxWidth) {
        document.getElementById('gpt_unit_' + anchorPath + '_0').style.display = 'block'
      }
    }
  }
  const autoRefresh = () => {
    if (generalConfigs.lazyLoad) {
      return false
    }
  }

  return {
    autoRefresh,
    onResize,
  }
}

export default optionsResize
