// For full documentation see
// https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration#size-mappings
//[1200, 0]
const trauerConfig = {
  sizeMappings: {
    SKY_Desktop: [
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
      {
        viewPortSize: [1200, 0],
        sizes: [[300, 600]],
      },
    ],
    SKY_Mobile: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 100]],
      },
      {
        viewPortSize: [1200, 0],
        sizes: [],
      },
    ],
    LL_Desktop: [
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
      {
        viewPortSize: [1200, 0],
        sizes: [[728, 90]],
      },
    ],
    LL_Mobile: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 100]],
      },
      {
        viewPortSize: [1200, 0],
        sizes: [],
      },
    ],
    Button: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 100]],
      },
    ],
    Button_Desktop: [
      {
        viewPortSize: [0, 0],
        sizes: [],
      },
      {
        viewPortSize: [1200, 0],
        sizes: [[300, 100]],
      },
    ],
    Button_Mobile: [
      {
        viewPortSize: [0, 0],
        sizes: [[300, 100]],
      },
      {
        viewPortSize: [1200, 0],
        sizes: [],
      },
    ],
  },
  slots: [
    {
      id: 'dlab-ad-hp-sky-desktop',
      globalId: 'hpSkyDesktop',
      path: '/2273514/dtp2_hp_sky',
      sizeMappingName: 'SKY_Desktop',
    },
    {
      id: 'dlab-ad-hp-sky-mobile',
      globalId: 'hpSkyMobile',
      path: '/2273514/dtp2_hp_sky',
      sizeMappingName: 'SKY_Mobile',
    },
    {
      id: 'dlab-ad-hp-cb-desktop',
      globalId: 'hpCbDesktop',
      path: '/2273514/dtp2_hp_ll_1',
      sizeMappingName: 'LL_Desktop',
    },
    {
      id: 'dlab-ad-hp-cb-mobile',
      globalId: 'hpCbMobile1',
      path: '/2273514/dtp2_hp_ll_1',
      sizeMappingName: 'LL_Mobile',
    },
    {
      id: 'dlab-ad-hp-button-1-desktop',
      path: '/2273514/dtp2_hp_but_1',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-hp-button-1-mobile',
      path: '/2273514/dtp2_hp_but_1',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-hp-button-2-desktop',
      path: '/2273514/dtp2_hp_but_2',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-hp-button-2-mobile',
      path: '/2273514/dtp2_hp_but_2',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-hp-button-3-desktop',
      path: '/2273514/dtp2_hp_but_3',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-hp-button-3-mobile',
      path: '/2273514/dtp2_hp_but_3',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-default-sky-desktop',
      globalId: 'defaultSkyDesktop',
      path: '/2273514/dtp2_default_sky',
      sizeMappingName: 'SKY_Desktop',
    },
    {
      id: 'dlab-ad-default-sky-mobile',
      globalId: 'defaultSkyMobile',
      path: '/2273514/dtp2_default_sky',
      sizeMappingName: 'SKY_Mobile',
    },
    {
      id: 'dlab-ad-default-cb-desktop',
      globalId: 'defaultCbDesktop',
      path: '/2273514/dtp2_default_ll_1',
      sizeMappingName: 'LL_Desktop',
    },
    {
      id: 'dlab-ad-default-cb-mobile',
      globalId: 'defaultCbMobile1',
      path: '/2273514/dtp2_default_ll_1',
      sizeMappingName: 'LL_Mobile',
    },
    {
      id: 'dlab-ad-default-button-1-desktop',
      path: '/2273514/dtp2_default_but_1',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-default-button-1-mobile',
      path: '/2273514/dtp2_default_but_1',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-default-button-2-desktop',
      path: '/2273514/dtp2_default_but_2',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-default-button-2-mobile',
      path: '/2273514/dtp2_default_but_2',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-default-button-3-desktop',
      path: '/2273514/dtp2_default_but_3',
      sizeMappingName: 'Button',
    },
    {
      id: 'dlab-ad-default-button-3-mobile',
      path: '/2273514/dtp2_default_but_3',
      sizeMappingName: 'Button',
    },
  ],
  general: {
    reloadInterval: true,
    collapseEmptyDivs: true,
    disableInitialLoad: false,
    enableSingleRequest: false,
    preloadedFiles: [
      {
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
    ],
  },
  viewports: {
    desktop: [1200, 0],
    'tablet-lg': [992, 0],
    'tablet-md': [768, 0],
    all: [0, 0],
  },
}

export default trauerConfig
