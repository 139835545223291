const settingsDefault = () => ({
  Allgemein: {
    settings: [
      {
        label: 'detailierte Fehlerausgabe',
        valueKey: 'dlabShowErrors',
        type: 'switch',
      },
    ],
    expanded: true,
  },
})

export default settingsDefault
