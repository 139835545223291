const settingsAds = () => ({
  Ads: {
    settings: [
      {
        label: 'Test Banner aktivieren',
        valueKey: 'dlabAdsTest',
        type: 'switch',
      },
      {
        label: 'Test Banner mit benutzerdefiniertem Type',
        valueKey: 'dlabAdsTypeTest',
        type: 'textarea',
      },
      {
        label: 'Console Logs - Banner',
        valueKey: 'dlabAdsLogTest',
        type: 'switch',
      },
      {
        label: 'Console Logs - Amazon TAM Debug',
        valueKey: 'dlabAmazonTAMDebugTest',
        type: 'switch',
      },
      {
        label: 'Console Logs - Outbrain',
        valueKey: 'dlabOutbrainTest',
        type: 'switch',
      },
    ],
  },
})

export default settingsAds
