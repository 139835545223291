import isServerSide from '../../../../modules/is-server-side'
import slcOnLoad from '../../modules/slc-on-load'

const viewports = {
  all: [0, 0],
}

const sizes = {
  superBanner: [728, 90],
  smallSuperBanner: [468, 60],
  sky: [300, 600],
  thinSky: [160, 600],
  rectangle: [300, 250],
}

const wattenOrgConfig = {
  sizeMappings: {
    SUPERBANNER: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.superBanner, sizes.smallSuperBanner],
      },
    ],
    SKY: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.sky, sizes.thinSky],
      },
    ],
    REC: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.rectangle],
      },
    ],
  },

  slots: [
    {
      id: 'dlab-watten-sb1',
      path: '/2273514/WATTEN-ORG_sb1',
      sizeMappingName: 'SUPERBANNER',
    },
    {
      id: 'dlab-watten-sb2',
      path: '/2273514/WATTEN-ORG_sb2',
      sizeMappingName: 'SUPERBANNER',
    },
    {
      id: 'dlab-watten-sky1',
      path: '/2273514/WATTEN-ORG_sky1',
      sizeMappingName: 'SKY',
    },
    {
      id: 'dlab-watten-rec1',
      path: '/2273514/WATTEN-ORG_rec1',
      sizeMappingName: 'REC',
    },
  ],

  general: {
    reloadInterval: true,
    collapseEmptyDivs: true,
    disableInitialLoad: true,
    enableSingleRequest: true,
    userData: {
      email: () => {
        if (isServerSide()) {
          return
        }
        if (window.spieleremail && window.spieleremail !== 'null') {
          return window.spieleremail
        }
      },
    },
    lazyLoad: {
      offset: 500,
    },
    prebid: {
      active: true,
      fledgeConfigPlayload: {
        paapi: {
          enabled: true,
          gpt: {
            autoconfig: false,
          },
          defaultForSlots: 1,
          bidders: ['criteo' /* other bidders */],
        },
      },
      ortb2UserData: {
        bidders: ['criteo'],
        source: 'https://www.watten.org',
      },
      liveramp: true,
    },
    adBlockingRecovery: {
      frequencyCap: {
        type: 'time',
        amount: 10,
      },
    },
    amazontam: true,
    preloadedFiles: [
      {
        link: 'https://connect.suedtirol.live/slc.js',
        async: true,
        onload: () =>
          slcOnLoad({
            id: 'SLC-HXKQGRLA',
            enableTargets: true,
            prefixUserId: 'WAT',
          }),
      },
      {
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
      {
        link: 'https://launchpad-wrapper.privacymanager.io/8635aa92-be83-46e4-945a-975b0f76a243/launchpad-liveramp.js',
        async: true,
      },
      {
        link: 'https://www.googletagmanager.com/gtag/js?id=G-BZVJB9M5XZ',
        async: true,
      },
    ],
    reloader: {
      interval: 60,
      campaignIds: [293423654, 2502405800, 2419055722, 164280974, 2717153009],
      adUnitsIds: [
        '/2273514/WATTEN-ORG_sb1',
        '/2273514/WATTEN-ORG_sb2',
        '/2273514/WATTEN-ORG_sky1',
        '/2273514/WATTEN-ORG_rec1',
      ],
      onReloadSuccess: function (adUnit) {
        console.log('Reloaded Ads!')
        window.dataLayer = window.dataLayer || []
        function gtag() {
          dataLayer.push(arguments)
        }
        gtag('js', new Date())
        gtag('config', 'G-BZVJB9M5XZ')
        gtag('event', 'Reload', {
          event_label: adUnit,
          event_category: 'AdReloaded',
          non_interaction: true,
        })
      },
    },
  },
}

export default wattenOrgConfig
