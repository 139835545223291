// For full documentation see
// https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration#size-mappings

import slcOnLoad from '../../modules/slc-on-load'
import { injectPositions } from '../../../../modules/interact-with-html'

const viewports = {
  tablet: [970, 0],
  mobile: [768, 0],
  mobile_s: [320, 0],
  all: [0, 0],
}

const restaurantsConfig = {
  // GPT size mapping definitions (object). Ideally there is a sizeMapping for each Slot
  viewports: viewports,
  sizeMappings: {
    // Homepage
    LL: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: [
          [300, 250],
          [300, 100],
          [300, 50],
        ],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [[728, 90]],
      },
      {
        viewPortSize: viewports.tablet,
        sizes: [
          [970, 150],
          [970, 90],
        ],
      },
    ],
    LL_MOBILE: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: [
          [300, 250],
          [300, 100],
          [300, 50],
        ],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [],
      },
    ],
    REC: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: [
          [300, 250],
          [300, 100],
          [300, 50],
        ],
      },
    ],
    REC_MOBILE: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: [
          [300, 250],
          [300, 100],
          [300, 50],
        ],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [],
      },
    ],
  },

  // array of slot configurations
  slots: [
    // Homepage
    {
      id: 'dlab-ad-S1-desktop-mobile',
      inject: {
        paths: ['/de/', '/it/', '/en/'],
        styleParent: 'display: flex; justify-content: center;',
        selector: '.content-c__category.content-c__category--htmlblock',
        selectorsPosition: 2,
        position: injectPositions.AFTER,
      },
      path: '/2273514/Restaurants/restaurants_leaderboard',
      sizeMappingName: 'LL',
    },
    // Suche Top
    {
      id: 'dlab-ad-S1-desktop',
      inject: {
        paths: ['/de/suche/', '/it/ricerca/', '/en/search/'],
        styleParent: 'display: flex; justify-content: center;',
        styleAd: 'margin-top:24px;',
        selector: '.content-c.is-grey > .container',
        position: injectPositions.AFTER,
      },
      path: '/2273514/Restaurants/restaurants_leaderboard',
      sizeMappingName: 'LL',
    },
    // Suche Mobil
    {
      id: 'dlab-ad-S1-mobile',
      inject: {
        paths: ['/de/suche/', '/it/ricerca/', '/en/search/'],
        styleParent: 'display: flex; justify-content: center;',
        styleAd: 'margin-top:24px;',
        selector: '.catblock.catblock--article',
        selectorsPosition: 2,
        position: injectPositions.AFTER,
      },
      path: '/2273514/Restaurants/restaurants_leaderboard',
      sizeMappingName: 'LL_MOBILE',
    },
    // Suche Sidebar
    {
      id: 'dlab-ad-S2-desktop',
      inject: {
        paths: ['/de/suche/', '/it/ricerca/', '/en/search/'],
        styleParent: 'display:flex; width: 100%;',
        styleAd: 'margin-top:24px;',
        selector: '#filtersidebar > .row > .col-xs-12',
        selectorsPosition: 2,
        position: injectPositions.AFTER,
      },
      path: '/2273514/Restaurants/restaurants_rectangle',
      sizeMappingName: 'REC',
    },
    // Suche Mobil
    {
      id: 'dlab-ad-S2-mobile',
      inject: {
        paths: ['/de/suche/', '/it/ricerca/', '/en/search/'],
        styleParent: 'display: flex; justify-content: center;',
        styleAd: 'margin-top:24px;',
        selector: '.catblock.catblock--article',
        selectorsPosition: 8,
        position: injectPositions.AFTER,
      },
      path: '/2273514/Restaurants/restaurants_rectangle',
      sizeMappingName: 'REC_MOBILE',
    },
  ],

  general: {
    disableInitialLoad: false,
    collapseEmptyDivs: true,
    enableSingleRequest: true,
    disableAddScrollbarWidth: true,
    adBlockingRecovery: {
      frequencyCap: {
        type: 'time',
        amount: 10,
      },
    },
    preloadedFiles: [
      {
        link: 'https://connect.suedtirol.live/slc.js',
        async: true,
        onload: () =>
          slcOnLoad({
            id: 'SLC-QZAMSV6X',
            enableTargets: true,
            prefixUserId: 'RST',
          }),
      },
      {
        idLoadOnce: 'gpt-script',
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
    ],
  },
}

export default restaurantsConfig
