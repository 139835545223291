const uniqueArrayOfArrays = (array = []) => {
  let uniqueArray = []

  array.forEach((arrayElement) => {
    const arrayElementString = arrayElement.join(',')
    let isUnique = true

    uniqueArray.forEach((uniqueElement) => {
      const uniqueElementString = uniqueElement.join(',')

      if (arrayElementString == uniqueElementString) {
        isUnique = false
      }
    })

    if (isUnique) {
      uniqueArray.push(arrayElement)
    }
  })

  return uniqueArray
}

export default uniqueArrayOfArrays
