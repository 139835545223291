// For full documentation see
// https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration#size-mappings

// The old values before we removed "disableAddScrollbarWidth". For more info see the property below
// (general.disableAddScrollbarWidth)
// const viewports = {
//   desktopL: [1494, 0],
//   desktop: [1183, 0],
//   desktopS: [953, 0],
//   tabletL: [943, 0],
//   tablet: [730, 0],
//   tabletS: [623, 0],
//   mobile: [320, 0],
//   all: [0, 0],
// }

import handlerMediaqueryTag from '../../../../modules/handler-mediaquery-tag'
import getSplusEmail from '../../modules/get-splus-email'
import slcOnLoad from '../../modules/slc-on-load'

const viewports = {
  desktopL: [1511, 0],
  desktop: [1200, 0],
  desktopS: [970, 0],
  tabletL: [960, 0],
  tablet: [747, 0],
  tabletS: [640, 0],
  mobile: [320, 0],
  all: [0, 0],
}

const { create: createMediaQueryTag } = handlerMediaqueryTag({ viewports })

const sizes = {
  button: [300, 100],
  buttonS: [300, 50],
  buttonAds: [320, 100],
  buttonAdsS: [320, 50],
  fullSize: [468, 60],
  largeLeaderBoard: [970, 90],
  largeLeaderBoardS: [728, 90],
  rectangle: [300, 250],
  billboard: [970, 250],
  billboardS: [728, 200],
  superBanner: [970, 150],
  superBannerS: [728, 150],
  sky: [300, 600],
  skyS: [160, 600],
  wallpaper: [1920, 1080],
}

const sizeCombos = {
  buttons: [sizes.buttonAds, sizes.buttonAdsS, sizes.button, sizes.buttonS],
  buttons_s: [sizes.buttonAdsS, sizes.buttonS],
  largeLeaderboards: [sizes.superBanner, sizes.largeLeaderBoard, sizes.largeLeaderBoardS],
}

const customCss = {
  sky: `
    ${createMediaQueryTag({ max: 'desktopL', min: 'tabletS' })} {
      max-height: 90px;
    }
    ${createMediaQueryTag({ max: 'tabletS' })} {
      max-height: 50px;
    }
  `,
}

// Config for Sportnews
const spnConfig = {
  // GPT size mapping definitions (object). Ideally there is a sizeMapping for each Slot
  viewports: viewports,
  sizeMappings: {
    // Homepage
    sbTabletDesktop: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.tabletS,
        sizes: sizeCombos.buttons,
      },
      {
        viewPortSize: viewports.tablet,
        sizes: [sizes.superBannerS, sizes.largeLeaderBoardS],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: sizeCombos.largeLeaderboards,
      },
    ],
    sbMobile: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: sizeCombos.buttons,
      },
      {
        viewPortSize: viewports.tabletS,
        sizes: [],
      },
    ],
    skyDesktop: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.desktopL,
        sizes: [sizes.sky, sizes.skyS],
      },
    ],
    skyTabletMobile: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: sizeCombos.buttons_s,
      },
      {
        viewPortSize: viewports.tablet,
        sizes: [sizes.largeLeaderBoardS],
      },
      {
        viewPortSize: viewports.desktopL,
        sizes: [],
      },
    ],
    buttonTopDesktop: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.tabletL,
        sizes: sizeCombos.buttons_s,
      },
    ],
    buttonTopTablet: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.tabletS,
        sizes: sizeCombos.buttons_s,
      },
      {
        viewPortSize: viewports.tabletL,
        sizes: [],
      },
    ],
    buttonTopMobile: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: sizeCombos.buttons_s,
      },
      {
        viewPortSize: viewports.tabletS,
        sizes: [],
      },
    ],
    rec: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.rectangle],
      },
    ],
    recDesktop: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.tabletL,
        sizes: [sizes.rectangle],
      },
    ],
    recTablet: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.tabletS,
        sizes: [sizes.rectangle],
      },
      {
        viewPortSize: viewports.tabletL,
        sizes: [],
      },
    ],
    recMobile: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.rectangle],
      },
      {
        viewPortSize: viewports.tabletS,
        sizes: [],
      },
    ],
    recTabletDesktop: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.tabletS,
        sizes: [sizes.rectangle],
      },
    ],
    sponsor: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [[160, 50]],
      },
    ],
    inread: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [[1, 1]],
      },
    ],
    but: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: sizeCombos.buttons,
      },
    ],
    ll: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.button, sizes.rectangle, sizes.buttonAds],
      },
      {
        viewPortSize: viewports.tablet,
        sizes: [sizes.superBannerS, sizes.largeLeaderBoardS, [640, 120]],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.superBanner, sizes.superBannerS, sizes.largeLeaderBoardS],
      },
    ],
    widgetFull: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.buttonAds],
      },
      {
        viewPortSize: viewports.desktopL,
        sizes: [[1200, 100]],
      },
    ],
    widgetLeft: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.largeLeaderBoardS, sizes.buttonAds],
      },
    ],
    widgetRight: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [[370, 100]],
      },
    ],
    video: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [[640, 360]],
      },
    ],
    IS: [
      {
        viewPortSize: [960, 0],
        sizes: [],
      },
      {
        viewPortSize: [0, 0],
        sizes: [
          [300, 250],
          [320, 480],
        ],
      },
    ],
  },

  // array of slot configurations
  slots: [
    // Interstitial
    {
      id: 'dlab-ad-IS-web',
      path: '/2273514/sportnews2_web_is',
      sizeMappingName: 'IS',
    },
    // Homepage
    {
      // ID of the div
      id: 'dlab_ad_H1_tablet_desktop',
      // slot's ad unit path
      path: '/2273514/sportnews2_hp_sb',
      // name of sizeMapping that is defined for this slot
      sizeMappingName: 'sbTabletDesktop',
    },
    {
      id: 'dlab_ad_H1_mobile',
      path: '/2273514/sportnews2_hp_sb', //tablet: hinter Banner versteckt
      sizeMappingName: 'sbMobile',
    },
    {
      id: 'dlab_ad_H2_desktop',
      path: '/2273514/sportnews2_hp_sky', //tablet - mobile: kommt nicht raus
      sizeMappingName: 'skyDesktop',
    },
    {
      id: 'dlab_ad_H3_desktop',
      path: '/2273514/sportnews2_hp_but_top',
      sizeMappingName: 'buttonTopDesktop',
    },
    {
      id: 'dlab_ad_H3_tablet',
      path: '/2273514/sportnews2_hp_but_top',
      sizeMappingName: 'buttonTopTablet',
    },
    {
      id: 'dlab_ad_H3_mobile',
      path: '/2273514/sportnews2_hp_but_top',
      sizeMappingName: 'buttonTopMobile',
    },

    {
      id: 'dlab_ad_H4_desktop',
      path: '/2273514/sportnews2_hp_rec1',
      sizeMappingName: 'recDesktop',
    },
    {
      id: 'dlab_ad_H4_tablet',
      path: '/2273514/sportnews2_hp_rec1',
      sizeMappingName: 'recTablet',
    },
    {
      id: 'dlab_ad_H4_mobile',
      path: '/2273514/sportnews2_hp_rec1',
      sizeMappingName: 'recMobile',
    },

    {
      id: 'dlab_ad_H5_desktop',
      path: '/2273514/sportnews2_hp_rec2',
      sizeMappingName: 'recDesktop',
    },
    {
      id: 'dlab_ad_H5_tablet',
      path: '/2273514/sportnews2_hp_rec2',
      sizeMappingName: 'recTablet',
    },
    {
      id: 'dlab_ad_H5_mobile',
      path: '/2273514/sportnews2_hp_rec2',
      sizeMappingName: 'recMobile',
    },

    {
      id: 'dlab_ad_H6_desktop',
      path: '/2273514/sportnews2_hp_rec3',
      sizeMappingName: 'recDesktop',
    },
    {
      id: 'dlab_ad_H6_tablet',
      path: '/2273514/sportnews2_hp_rec3',
      sizeMappingName: 'recTablet',
    },
    {
      id: 'dlab_ad_H6_mobile',
      path: '/2273514/sportnews2_hp_rec3',
      sizeMappingName: 'recMobile',
    },

    {
      id: 'dlab_ad_H7_desktop',
      path: '/2273514/sportnews2_hp_rec4',
      sizeMappingName: 'recDesktop',
    },
    {
      id: 'dlab_ad_H7_tablet',
      path: '/2273514/sportnews2_hp_rec4',
      sizeMappingName: 'recTablet',
    },
    {
      id: 'dlab_ad_H7_mobile',
      path: '/2273514/sportnews2_hp_rec4',
      sizeMappingName: 'recMobile',
    },

    {
      id: 'dlab_ad_H8_desktop',
      path: '/2273514/sportnews2_hp_rec5',
      sizeMappingName: 'recDesktop',
    },
    {
      id: 'dlab_ad_H8_tablet',
      path: '/2273514/sportnews2_hp_rec5',
      sizeMappingName: 'recTablet',
    },
    {
      id: 'dlab_ad_H8_mobile',
      path: '/2273514/sportnews2_hp_rec5',
      sizeMappingName: 'recMobile',
    },

    {
      id: 'dlab_ad_H9_desktop',
      path: '/2273514/sportnews2_hp_rec6', // kommt nicht raus
      sizeMappingName: 'recDesktop',
    },
    {
      id: 'dlab_ad_H9_tablet',
      path: '/2273514/sportnews2_hp_rec6', // kommt nicht raus
      sizeMappingName: 'recTablet',
    },
    {
      id: 'dlab_ad_H9_mobile',
      path: '/2273514/sportnews2_hp_rec6', // kommt nicht raus
      sizeMappingName: 'recMobile',
    },

    {
      id: 'dlab_ad_H10_desktop',
      path: '/2273514/sportnews2_hp_rec7', // nicht eingebunden
      sizeMappingName: 'recDesktop',
    },
    {
      id: 'dlab_ad_H10_tablet',
      path: '/2273514/sportnews2_hp_rec7', // nicht eingebunden
      sizeMappingName: 'recTablet',
    },
    {
      id: 'dlab_ad_H10_mobile',
      path: '/2273514/sportnews2_hp_rec7', // nicht eingebunden
      sizeMappingName: 'recMobile',
    },

    {
      id: 'dlab_ad_H11_desktop',
      path: '/2273514/sportnews2_hp_rec8', // nicht eingebunden
      sizeMappingName: 'recDesktop',
    },
    {
      id: 'dlab_ad_H11_tablet',
      path: '/2273514/sportnews2_hp_rec8', // nicht eingebunden
      sizeMappingName: 'recTablet',
    },
    {
      id: 'dlab_ad_H11_mobile',
      path: '/2273514/sportnews2_hp_rec8', // nicht eingebunden
      sizeMappingName: 'recMobile',
    },

    {
      id: 'dlab_ad_H12_desktop',
      path: '/2273514/sportnews2_hp_rec9', // nicht eingebunden
      sizeMappingName: 'recDesktop',
    },
    {
      id: 'dlab_ad_H12_tablet',
      path: '/2273514/sportnews2_hp_rec9', // nicht eingebunden
      sizeMappingName: 'recTablet',
    },
    {
      id: 'dlab_ad_H12_mobile',
      path: '/2273514/sportnews2_hp_rec9', // nicht eingebunden
      sizeMappingName: 'recMobile',
    },

    {
      id: 'dlab_ad_H13_desktop',
      path: '/2273514/sportnews2_hp_rec10', // nicht eingebunden
      sizeMappingName: 'recDesktop',
    },
    {
      id: 'dlab_ad_H13_tablet',
      path: '/2273514/sportnews2_hp_rec10', // nicht eingebunden
      sizeMappingName: 'recTablet',
    },
    {
      id: 'dlab_ad_H13_mobile',
      path: '/2273514/sportnews2_hp_rec10', // nicht eingebunden
      sizeMappingName: 'recMobile',
    },

    //Artikel
    {
      id: 'dlab_ad_S1_tablet_desktop',
      path: '/2273514/sportnews2_default_sb',
      sizeMappingName: 'sbTabletDesktop',
    },
    {
      id: 'dlab_ad_S1_mobile',
      path: '/2273514/sportnews2_default_sb',
      sizeMappingName: 'sbMobile',
    },
    {
      id: 'dlab_ad_S2_desktop',
      path: '/2273514/sportnews2_default_sky', //tablet - mobile: kommt nicht raus
      sizeMappingName: 'skyDesktop',
    },
    {
      id: 'dlab_ad_S3',
      path: '/2273514/sportnews2_default_sponsor', // nicht getestet
      sizeMappingName: 'sponsor', //Sportnews Standard Superbanner (bei Buchung Sponsored  wird S1 in Artikeldetailseite Mobil nicht ausgespielt)
    },

    {
      id: 'dlab_ad_S4',
      path: '/2273514/sportnews2_default_inread',
      sizeMappingName: 'inread',
    },

    {
      id: 'dlab_ad_S5',
      path: '/2273514/sportnews2_default_rec1', // kommt manchmal nicht raus - artikel
      sizeMappingName: 'rec',
    },
    {
      id: 'dlab_ad_S5_mobile',
      path: '/2273514/sportnews2_default_rec1',
      sizeMappingName: 'recMobile',
    },
    {
      id: 'dlab_ad_S5_tablet_desktop',
      path: '/2273514/sportnews2_default_rec1',
      sizeMappingName: 'recTabletDesktop',
    },
    {
      id: 'dlab_ad_S6',
      path: '/2273514/sportnews2_default_rec2', // kommt manchmal nicht raus - fussball
      sizeMappingName: 'rec',
    },

    {
      id: 'dlab_ad_S7',
      path: '/2273514/sportnews2_default_but_1',
      sizeMappingName: 'but',
    },
    {
      id: 'dlab_ad_S8',
      path: '/2273514/sportnews2_default_but_2',
      sizeMappingName: 'but',
    },

    {
      id: 'dlab_ad_S9',
      path: '/2273514/sportnews2_default_ll_1',
      sizeMappingName: 'll',
    },
    {
      id: 'dlab_ad_S10',
      path: '/2273514/sportnews2_default_ll_2',
      sizeMappingName: 'll',
    },
    {
      id: 'dlab_ad_V1',
      path: '/2273514/sportnews2_video_pre', // nicht eingebunden
      sizeMappingName: 'video',
    },
    {
      id: 'dlab_ad_V2',
      path: '/2273514/sportnews2_video_post', // nicht eingebunden
      sizeMappingName: 'video',
    },
  ],

  general: {
    collapseEmptyDivs: true,
    disableInitialLoad: true,
    enableSingleRequest: true,
    /*

    We removed that property because it caused a discrepancy with ad breakpoints (H2 - Sky). We couldn't figure out
    why this prop was needed and so we threw it out ;-) Should ads act weird in other placed you should consider
    readding it and tinker/fix the logic that lies behind it (initAds.defineSlots). Don't forget to readd the old
    sizemappings when you do it (first block of this file).

    */
    // disableAddScrollbarWidth: true,
    testValue: '1',
    userData: {
      email: getSplusEmail,
    },
    lazyLoad: {
      offset: 500,
    },
    prebid: {
      active: true,
      liveramp: true,
      fledgeConfigPlayload: {
        paapi: {
          enabled: true,
          gpt: {
            autoconfig: false,
          },
          defaultForSlots: 1,
          bidders: ['criteo' /* other bidders */],
        },
      },
      ortb2UserData: {
        bidders: ['criteo'],
        source: 'https://www.sportnews.bz',
      },
    },
    adBlockingRecovery: {
      frequencyCap: {
        type: 'time',
        amount: 10,
      },
    },
    customRulesUrls: [
      {
        url: '/anmelden',
        exact: false,
        noAds: true,
      },
      {
        url: '/lesezeichen',
        exact: false,
        noAds: true,
      },
      {
        url: '/profil',
        exact: false,
        noAds: true,
      },
      {
        url: '/p/kuendigen',
        exact: false,
        noAds: true,
      },
    ],
    amazontam: true,
    preloadedFiles: [
      {
        link: 'https://connect.suedtirol.live/slc.js',
        async: true,
        onload: () => {
          slcOnLoad({
            id: 'SLC-DRXMQC0F',
            enableTargets: true,
            prefixUserId: 'SPN',
          })
          window.addEventListener('message', function (event) {
            if (!event.origin.endsWith('.pinpoll.com') || !event.data || event.data.event != 'vote') {
              return
            }
            console.log('dbg_DT')
            if (event.data && event.data.audiences) {
              console.log('dbg_DT_A')
              const interestIds = event.data.audiences.interests.map((interest) => interest.id)
              if (interestIds.length > 0) {
                console.log('dbg_DT_A_SND')
                SLC('pageEvent', 'pinpoll', 'vote', '', '', JSON.stringify(interestIds))
              }
            }
          })
        },
      },
      {
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
      {
        link: 'https://launchpad-wrapper.privacymanager.io/8635aa92-be83-46e4-945a-975b0f76a243/launchpad-liveramp.js',
        async: true,
      },
    ],
    anchorAd: {
      path: '/2273514/sportnews2_<LOCATION>_sky',
      position: 'bottom',
      maxWidth: viewports.desktopL,
    },
    interstitial: {
      // [ToDo]: Refactor that shit
      // showInViewports: ['mobile', 'mobile_s', 'tablet', 'tablet_s'],
      containerId: 'dlab-interstitial',
      slotId: 'dlab-ad-IS-web',
      // ga4eventName: 'web-interstitial',
      slotPath: '/2273514/sportnews2_web_is',
      displayTimeInSec: 15,
    },
    reloader: {
      interval: 30,
      campaignIds: [293423654, 164280974],
      adUnitsIds: ['/2273514/sportnews2_hp_sky', '/2273514/sportnews2_default_sky', '/2273514/sportnews2_cat_sky'],
    },
    addCloseButton: {
      elements: [
        {
          ids: ['dlab_ad_H2_tablet_mobile', 'dlab_ad_S2_tablet_mobile'],
          width: [728],
        },
      ],
    },
  },
}

export default spnConfig
