import includeJsFile from '../../../../modules/include-js-file'
import consoleLogger from '../../../../modules/console-logger'
import { hashedEmails } from '../../modules/hashed-emails'
import liveRamp from './vendor/live-ramp'

const prebidMagnite = ({ googletag, config, onPrebidVideoSuccess, pageUrl }) => {
  const [consoleLog] = consoleLogger('AdsLog')
  let sizeMappings = {}

  const init = ({ slots, customOptions, adsRefresh = true, onSuccess = () => {} }) => {
    consoleLog('PREBID activated', '', 'Ads')
    consoleLog('PREBID sizeMappings', sizeMappings, 'Ads')

    const hasVersion = window.pbjs && window.pbjs.version

    if (!hasVersion) {
      let link = '//micro.rubiconproject.com/prebid/dynamic/24524.js'
      if (config?.wrapper) {
        link += '?wrapper=' + config.wrapper
      }
      includeJsFile({
        link: link,
        async: true,
      })

      window.pbjs = window.pbjs || {}
      window.pbjs.que = window.pbjs.que || []
    }

    const callAdServer = (gptSlots) => {
      if (window.pbjs.adserverCalled) {
        return
      }
      if (adsRefresh) {
        googletag.pubads().refresh(gptSlots)
        consoleLog('PREBID refresh ads', gptSlots, 'Ads')
      }
      onSuccess()
      consoleLog('PREBID request bids fired', window.pbjs, 'Ads')
    }

    let optionsRequestBids = {}

    optionsRequestBids.callback = callAdServer

    if (Object.keys(sizeMappings).length > 0) {
      optionsRequestBids.sizeMappings = sizeMappings
    }

    if (slots) {
      optionsRequestBids.gptSlotObjects = slots
    }

    if (typeof customOptions === 'object') {
      optionsRequestBids = {
        ...optionsRequestBids,
        ...customOptions,
      }
    }

    // request pbjs bids when it loads
    window.pbjs.que.push(function () {
      consoleLog('PREBID used options', optionsRequestBids, 'Ads')

      let prebidConfig = {}
      if (pageUrl) {
        consoleLog('PREBID set page url', pageUrl, 'Ads')
        prebidConfig.pageUrl = pageUrl
      }

      if (config?.fledgeConfigPlayload) {
        prebidConfig = {
          ...prebidConfig,
          ...config.fledgeConfigPlayload,
        }
        optionsRequestBids.bidsBackHandler = function () {
          pbjs.setPAAPIConfigForGPT()
          initAdserver()
        }
      }

      pbjs.setConfig(prebidConfig)

      hashedEmails({ config: config?.ortb2UserData })
      liveRamp({ config: config?.liveramp })

      window.pbjs.rp.requestBids(optionsRequestBids)
      sizeMappings = {}

      if (config && config.video) {
        window.pbjs.rp.requestVideoBids({
          adSlotName: config.video.adSlotName,
          playerSize: config.video.playerSize,
          callback: onPrebidVideoSuccess,
        })
      }
    })
  }

  let usedSlotId
  const getSizeMappings = ({ slotId, viewportSize, sizes }) => {
    if (slotId !== usedSlotId || sizeMappings[slotId] === undefined) {
      sizeMappings[slotId] = []
    }
    sizeMappings[slotId].push({
      minViewPort: viewportSize,
      sizes: sizes,
    })

    usedSlotId = slotId
  }

  return {
    init,
    getSizeMappings,
  }
}

export default prebidMagnite
