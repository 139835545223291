import consoleLogger from '../../../../modules/console-logger'
import { getStoredItem, setStoredItem } from '../../../../modules/stored-item'

const [consoleLog] = consoleLogger('AdsLog')

const validCapTypes = ['time', 'session']

const checkFrequencyCap = (type, amount, prefix = 'fq', saveWhenTrue = true) => {
  switch (type) {
    case 'time': {
      const storeKey = prefix + '_minuteslastshown'
      const minutesLastShown = parseInt(getStoredItem(storeKey) ?? 0)
      const currentMinutes = Math.floor(Date.now() / 1000 / 60)
      consoleLog('FrequencyCap Debug', 'Wanted time.. Last: ' + minutesLastShown + ', |<=| Current: ' + currentMinutes)
      if (minutesLastShown + amount <= currentMinutes) {
        if (saveWhenTrue) {
          writeToSessionStorage(type, prefix)
        }
        return true
      } else {
        return false
      }
    }
    // case 'pageViews': {
    //   const storeKey = prefix + '_viewssinceshown'
    //   const viewsSinceShown = parseInt(getStoredItem(storeKey) ?? -1)

    //   consoleLog('FrequencyCap Debug', 'Wanted views.. Views since: ' + viewsSinceShown + ', wanted: ' + amount)
    //   if (viewsSinceShown < 0 || viewsSinceShown >= amount) {
    //     setStoredItem(storeKey, 0)
    //     return true
    //   } else {
    //     setStoredItem(storeKey, viewsSinceShown + 1)
    //     return false
    //   }
    // }
    case 'session': {
      const storeKey = prefix + '_shown'

      consoleLog('FrequencyCap Debug', 'Wanted once/session.. shown: ' + getStoredItem(storeKey))
      if (!getStoredItem(storeKey)) {
        if (saveWhenTrue) {
          writeToSessionStorage(type, prefix)
        }
        return true
      } else {
        return false
      }
    }
    default: {
      consoleLog('CheckFrequencyCap', type + ' not a valid type of Frequency Cap! (' + validCapTypes.toString() + ')')
      return false
    }
  }
}

const writeToSessionStorage = (type, prefix = 'fq') => {
  switch (type) {
    case 'time': {
      const storeKey = prefix + '_minuteslastshown'
      const currentMinutes = Math.floor(Date.now() / 1000 / 60)
      setStoredItem(storeKey, currentMinutes)
      break
    }
    case 'session': {
      const storeKey = prefix + '_shown'
      setStoredItem(storeKey, 'true')
      break
    }
    default: {
      consoleLog('CheckFrequencyCap', type + ' not a valid type of Frequency Cap!')
    }
  }
}

const frequencyCapSlotResponseCheck = ({ slot, googletag }) => {
  const frequencyCapAllowed = checkFrequencyCap(slot.frequencyCap.type, slot.frequencyCap.amount, slot.id, false)
  if (!frequencyCapAllowed) {
    consoleLog(`div ${slot.id} blocked from FreqCap`)
    return false
  } else {
    googletag.pubads().addEventListener('slotResponseReceived', function (event) {
      if (slot.path === event.slot.getAdUnitPath()) {
        consoleLog(`div ${slot.id} gotten response from GAM`)
        writeToSessionStorage(slot.frequencyCap.type, slot.id)
      }
    })
  }
}

export { checkFrequencyCap, writeToSessionStorage, frequencyCapSlotResponseCheck }
