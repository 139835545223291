import throttle from 'lodash/throttle'
import differenceBy from 'lodash/differenceBy'
import uniqBy from 'lodash/uniqBy'
import merge from 'lodash/merge'
import consoleLogger from '../../../../modules/console-logger'
import handlerWindowDlab from '../handler-window-dlab'

const lazyLoading = ({ googletag, allSlots, config, slotDefiner, customTriggerElement = () => {} }) => {
  let processedSlots = []
  const [consoleLog] = consoleLogger('AdsLog')
  const init = ({ onRefresh, alreadyProcessedSlots = [] }) => {
    const { dlabOption } = handlerWindowDlab('ads', { sub: 'lazyLoading' })
    let processedSlots = alreadyProcessedSlots
    dlabOption.processedSlots = processedSlots
    if (!dlabOption['isInitiated']) {
      window.addEventListener(
        'scroll',
        throttle(
          () => {
            if (slotDefiner) {
              let processedSlots = dlabOption.processedSlots ?? []
              let currentlySeenSlots = checkAdUnitVisibility()
              let diffSlots = differenceBy(currentlySeenSlots, processedSlots, 'id')

              if (diffSlots.length > 0) {
                const newlyDefinedSlots = slotDefiner.init(diffSlots)
                if (newlyDefinedSlots.length > 0) {
                  consoleLog('LazyLoading - should refresh', newlyDefinedSlots)
                  const isOnRefresh = typeof onRefresh === 'function'
                  if (isOnRefresh) {
                    onRefresh({ newlyDefinedSlots })
                  }
                  if (!isOnRefresh && googletag) {
                    consoleLog('LazyLoading - GoogleTag', `refresh`)
                    googletag.pubads().refresh(newlyDefinedSlots)
                  }
                }
                consoleLog('New Slots in sight:', diffSlots, 'LazyLoadLog')
                processedSlots = processedSlots.concat(diffSlots)
                processedSlots = uniqBy(processedSlots, 'id')
                dlabOption.processedSlots = processedSlots
              }
            }
          },
          500,
          { trailing: true, leading: true },
        ),
      )
      dlabOption['isInitiated'] = true
    }
  }
  const setProcessedSlots = ({ slots }) => {
    processedSlots = merge(processedSlots, slots)
  }

  const checkAdUnitVisibility = (
    { slots = allSlots } = () => {
      slots = allSlots
    },
  ) => {
    let offset = config && config.offset
    let visibleSlots = []
    offset = offset ? offset : 0

    allSlots.forEach((slot) => {
      let el = customTriggerElement(slot) || document.getElementById(slot.id)

      if (el && (!el.innerHTML || !el.innerHTML.includes('<iframe'))) {
        var vw = window.innerWidth || document.documentElement.clientWidth,
          vh = window.innerHeight || document.documentElement.clientHeight,
          bounding = el.getBoundingClientRect()

        // checking whether fully visible
        if (
          bounding.top + offset >= 0 &&
          bounding.left >= 0 &&
          bounding.right <= vw &&
          bounding.bottom - offset <= vh
        ) {
          visibleSlots.push(slot)
        }
      }
    })
    return visibleSlots
  }

  return {
    init,
    checkAdUnitVisibility,
    setProcessedSlots,
  }
}

export default lazyLoading
