import userSession from '../../../../topics/Profile/modules/user-session'

const getSplusEmail = () => {
  const userData = userSession.getData() || userSession.getAppData()
  if (!userData) {
    return false
  }
  return userData.email
}

export default getSplusEmail
