import md5 from 'crypto-js/md5'
import sha256 from 'crypto-js/sha256'
import consoleLogger from '../../../../modules/console-logger'

const [consoleLog] = consoleLogger('AdsLog')

const hashedEmails = ({ config }) => {
  if (!config && !config?.bidders) {
    return
  }

  const email = dlab.user?.email
  if (!email) {
    consoleLog('PREBID hashed email: ', 'No dlab user information found -> general.userData', 'Ads')
    return
  }
  consoleLog('PREBID hashed email: ', email?.md5?.toString(), 'Ads')

  const hashedConfig = {
    bidders: config.bidders,
    config: {
      ortb2: {
        user: {
          ext: {
            data: {
              eids: [
                {
                  source: config.source,
                  uids: [
                    {
                      id: email.md5.toString(),
                      atype: 3,
                      ext: {
                        stype: 'hemmd5',
                      },
                    },
                    {
                      id: email.sha256.toString(),
                      atype: 3,
                      ext: {
                        stype: 'hemsha256',
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    },
  }
  consoleLog('PREBID Hashed Emails', hashedConfig, 'Ads')

  window.pbjs.setBidderConfig(hashedConfig)
}

const checkForEmail = ({ emailFunctionName }) => {
  if (window[emailFunctionName] === undefined) {
    consoleLog('CheckForEmail', 'no function found', 'Ads')
    return null
  }
  const email = window[emailFunctionName]()
  if (email) {
    consoleLog('CheckForEmail', 'email found - ' + email, 'Ads')
    return email
  } else {
    consoleLog('CheckForEmail', 'no email found', 'Ads')
    return null
  }
}

const hashMail = ({ email, algorithm = 'md5' }) => {
  let hashedMail = email
  switch (algorithm) {
    case 'md5': {
      hashedMail = md5(email)
      break
    }
    case 'sha256': {
      hashedMail = sha256(email)
      break
    }
    default: {
      consoleLog('hashMail', 'unknown algorithm - ' + algorithm, 'Ads')
      break
    }
  }
  return hashedMail
}

const createSessionIDAndHashedMail = ({ email, prefix } = { email: null, prefix: 'UKN' }) => {
  consoleLog('createSessionIDAndHashedMail', 'called')
  const ids = {}

  ids.sessionId = localStorage.getItem('sessionId')
  if (!ids.sessionId || !ids.sessionId.includes(prefix)) {
    const sessionId =
      prefix +
      '-' +
      Math.floor(Math.random() * Date.now()).toString(36) +
      '-' +
      Math.floor(Math.random() * Date.now()).toString(36)
    localStorage.setItem('sessionId', sessionId)
    ids.sessionId = sessionId
  }

  // if (localStorage.getItem('sessionUuid') == null) {
  //   const firstMilli = Date.now()
  //   const hashedFirstMilli = sha256(firstMilli)
  //   const secondMilli = Date.now()
  //   const hashedSecondMilli = sha256(hashedFirstMilli + '-' + secondMilli)

  //   const sessionUuid = 'UUID-' + hashedSecondMilli
  //   localStorage.setItem('sessionUuid', sessionUuid)
  // }

  if (email != null) {
    const hashedMail = hashMail({ email })
    localStorage.setItem('sessionHashedMail', hashedMail)
    ids.sessionHashedMail = hashedMail
  } else {
    localStorage.removeItem('sessionHashedMail')
  }

  return ids
}

const createSessionId = ({ email }) => {
  consoleLog('createSessionId', 'called')
  if (email != null) {
    localStorage.setItem('sessionId', hashMail({ email }))
  } else {
    if (localStorage.getItem('sessionId') == null || !localStorage.getItem('sessionId').startsWith('SID-')) {
      const sessionId = 'SID-' + Math.floor(Math.random() * Date.now()).toString(36)
      localStorage.setItem('sessionId', sessionId)
    }
    // if (localStorage.getItem('sessionUuid') == null) {
    //   const firstMilli = Date.now()
    //   const hashedFirstMilli = sha256(firstMilli)
    //   const secondMilli = Date.now()
    //   const hashedSecondMilli = sha256(hashedFirstMilli + '-' + secondMilli)

    //   const sessionUuid = 'UUID-' + hashedSecondMilli
    //   localStorage.setItem('sessionUuid', sessionUuid)
    // }
  }
}

export { hashedEmails, hashMail, createSessionId, createSessionIDAndHashedMail }
