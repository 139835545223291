const settingsVideos = () => ({
  Videos: {
    settings: [
      {
        label: 'Console Logs - Options Video',
        valueKey: 'dlabOptionsVideoTest',
        type: 'switch',
      },
    ],
  },
})

export default settingsVideos
