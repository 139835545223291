import addGlobalCss from '../add-global-css'
import viewportsCustomCss from './modules/viewports-custom-css'

const applyCustomCss = ({ slot }) => {
  if (!slot.customCss) {
    return false
  }
  const styleSlotId = `adCustomCss-${slot.id}`
  const existCustomStyle = document.getElementById(styleSlotId)
  if (existCustomStyle) {
    return false
  }

  let slotElementCss = viewportsCustomCss(slot.customCss) || slot.customCss

  slotElementCss = `
    #${slot.id} {
      ${slotElementCss}
    }
  `
  addGlobalCss(styleSlotId, slotElementCss)
}

export default applyCustomCss
