const settingsBookmarks = () => ({
  Lesezeichen: {
    settings: [
      {
        label: 'Console Logs - Allgemein',
        valueKey: 'dlabBookmarkTest',
        type: 'switch',
      },
      {
        label: 'Console Logs -> Lesezeichen Symbol',
        valueKey: 'dlabBookmarkSymbolTest',
        type: 'switch',
      },
    ],
  },
})

export default settingsBookmarks
